import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderConfirmationService } from './order-confirmation.service';

@Component({
  selector: 'app-order-confirmation',
  templateUrl: './order-confirmation.component.html',
  styleUrls: ['./order-confirmation.component.css']
})
export class OrderConfirmationComponent implements OnInit {

  id:number = 0;
  sid: any;
  pid: number = 0;

  isLoading : boolean = false;
  subscribeOrder: any;
  orderDTO: any = {};

  constructor(
    private _OrderConfirmationService: OrderConfirmationService,
    private route: ActivatedRoute,
    private router: Router,
  ) { 
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      this.sid = params['sid'];
      this.pid = params['pid'].length > 0 ? params['pid'] : 0;
      console.log(this.id, this.sid, this.pid);
  });    
  }

  ngOnInit(): void {
    this.LoadOrder();
  }

  LoadOrder(){
    this.isLoading = true;
    if (this.subscribeOrder) {
        this.subscribeOrder.unsubscribe();
    }
    this.subscribeOrder = this._OrderConfirmationService
        .GetOrderDetail(this.id, this.pid, this.sid)
        .subscribe(
            result => {
                this.isLoading = false;
                if (result) {
                  this.orderDTO = result.jsonObj;
                  console.log(this.orderDTO);
                }
                if(this.orderDTO != null){
                  
                }
                else{
                  //redirect to home page
                  this.router.navigate(['/']);
                }
            },
            error => {
                this.isLoading = false;
            }
        );

  }

}
