import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment' //'  environment/environment';
//import { environment } from 'src/environments/environment';
//import { Utils } from 'app/shared/common/utlis';
//import { AppConfig } from 'app/app.config';
//import { getToken } from '@angular/router/src/utils/preactivation';

// let Isexcludelist: string[] = [];

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private token: string = environment.token;

  constructor(
    private http: HttpClient,
    //private appConfig: AppConfig,
    ) {}
  private isLocal = true;

  getHeader(){
    /*
    let userid =this.appConfig.getUserId();
    let token =this.appConfig.getToken();
    if(userid!=null && token!=null)
    {
      let request = new HttpHeaders({'token':this.appConfig.getToken(),'userid' :this.appConfig.getUserId()} );
      return request;
    }
    */
    return new HttpHeaders({'token': this.token} );;
  }




  post<T>(url:string, obj:any) {
    //if (obj != null && !this.isLocal) {
    //  obj = Utils.en(JSON.stringify(obj));
    //}
    const headers = this.getHeader();
    return this.http.post<T>(url, obj, {headers}).pipe(
      map((res: any) => {
        return this.mapResponse(url, res);
      })
    );
  }
  put<T>(url:string, obj:any) {
    //if (obj != null && !this.isLocal) {
    //  obj = Utils.en(JSON.stringify(obj));
    //}
    const headers = this.getHeader();
    return this.http.put<T>(url, obj, {headers}).pipe(
      map((res: any) => {
        return this.mapResponse(url, res);
      })
    );
  }

  getList<T>(url:string, parameters:any) {
    const input = Object.assign({}, parameters);
    //if (input != null && input.q != null && !this.isLocal) {
    //  input.q = Utils.en(JSON.stringify(input.q));
    //} else {
    //  input.q = JSON.stringify(input.q);
    //}
    input.q = JSON.stringify(input.q);
    const headers = this.getHeader();
    return this.http
      .get<T>(url, {
        params: input,
        headers: headers,
      })
      .pipe(
        map((res: any) => {
          return this.mapResponse(url, res);
        })
      );
  }

  mapResponse(url:string, res:any) {
    // let isExcludeflag = Isexcludelist(url);
    //return this.isLocal ? res : JSON.parse(Utils.cr(res));
    return res;
  }

  getById<T>(url:string, id:any) {
    const headers = this.getHeader();
    return this.http.get<T>(url + '/' + id, {headers}).pipe(
      map((res: any) => {
        return this.mapResponse(url, res);
      })
    );
  }

  /*
  getPdfByID(url) {
    const headers = new HttpHeaders();
    const httpOptions = {
      responseType: 'arraybuffer' as 'json',
      headers: headers
      // 'responseType'  : 'blob' as 'json'        //This also worked
    };
    return this.http.get<any>(url, httpOptions);
  }
  */

  get<T>(url:any) {
    const headers = this.getHeader();
    return this.http.get<T>(url, {headers}).pipe(
      map((res: any) => {
        return this.mapResponse(url, res);
      })
    );
  }
}
