import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CheckoutService } from './checkout.service';
import { Utils } from '../../../core/common/utils'
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {
  private stripe: Stripe;
  id:number = 0;
  sid: any;

  isLoadingSession : boolean = false;
  subscribeSession: any;
  sessionDTO: any = {};

  isLoadingOrder : boolean = false;
  subscribeOrder: any;
  orderDTO: any = {};

  dto: any = {};

  constructor(
    private _CheckoutService: CheckoutService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      this.sid = params['sid'];
      this.sessionDTO = {
        Id: this.sid,
        ProductId: this.id,
        CompanyId: Utils.CompanyId,
        CurrentStep: 1,
        CustomerId: null,
      };
      this.dto = {
        SessionId: this.sid,
        ProductId: this.id,
        CompanyId: Utils.CompanyId,
        AdditionalCustomerNote: "",
        RingSizeId: 5,
        Price: 0,
        PaymentToken: "",
        Customer: {
          CompanyId: Utils.CompanyId,
          FirstName: "",
          LastName: "",
          MiddleName: "",
          GenderId: null,
          Email: "",
          Phone: "",
          AddressId: null,
          AddressDTO:{
            Address1: "",
            Address2: "",
            City: "",
            State: "",
            Zipcode: "",
            Country: "US",
          }
        }
      };

  });
  }

  ngOnInit(): void {
    this.loadPayment();
    this.LoadProductSession(true);
  }

  LoadProductSession(isOnInit: Boolean = false){
    this.isLoadingSession = true;
    if (this.subscribeSession) {
        this.subscribeSession.unsubscribe();
    }
    this.subscribeSession = this._CheckoutService
        .GetProductSession(this.id, this.sid)
        .subscribe(
            result => {
                this.isLoadingSession = false;
                if (result.isSuccess) {
                  this.sessionDTO = result.jsonObj;
                  console.log(this.sessionDTO);
                }
                if(this.sessionDTO != null){
                  if(isOnInit){
                    this.sessionDTO.KeyDiamond = null;
                    this.sessionDTO.KeyDiamondId = null;
                    this.sessionDTO.KeyDiamondPrice = null;
                  }
                  this.dto.Price = this.sessionDTO.Total  || 50;
                }
                else{
                  //redirect to home page
                  this.router.navigate(['/']);
                }
            },
            error => {
                this.isLoadingSession = false;
            }
        );
  }

  PlaceOrder(){
    console.log(this.dto);
    this.isLoadingOrder = true;
    if (this.subscribeOrder) {
      this.subscribeOrder.unsubscribe();
    }
    this.subscribeOrder = this._CheckoutService
        .OnlinePlaceOrder(this.dto)
        .subscribe(
            result => {
                this.isLoadingOrder = false;
                if (result.isSuccess) {
                    this.orderDTO = result.jsonObj;
                    localStorage.removeItem('product'+this.id);
                    this.router.navigate(['/orderconfirmation'],{queryParams:{ id: this.orderDTO.Id, sid: this.sessionDTO.Id, pid: this.orderDTO.ProductId}});
                    console.log(this.orderDTO);
                } else {
                  const errorElement = <HTMLElement>document.getElementById('payment-errors');
                  errorElement.textContent = result.message;
                }
            },
            error => {
                this.isLoadingOrder = false;
            }
    );
  }
  async loadPayment() {
    this.stripe = await loadStripe(environment.stripeAPIKey);
    const elements = this.stripe.elements();

    const style = {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: (window.innerWidth <= 500) ? '12px' : '16px',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    };

    const card = elements.create('card', { style });


    card.mount('#card-element');

    card.on('change', (event:any) => {
      const displayError = <HTMLElement>document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });

    const button = <HTMLElement>document.getElementById('button');
    button.addEventListener('click', (event) => {
      event.preventDefault();
      const ownerInfo = {
        owner: {
          name : this.dto.Customer.FirstName + ' ' + this.dto.Customer.LastName || 'user'
          //,email: this.dto.Customer.Email || ''
        },
        amount: Math.round(this.sessionDTO.Total) || 50,
        currency: 'usd'
      };

      this.stripe.createSource(card, ownerInfo).then((result:any) => {
        //debugger
        console.log(result);
        if (result.error) {
          const errorElement = <HTMLElement>document.getElementById('card-errors');
          errorElement.textContent = result.error.message;
        } else {
          this.dto.Price = result.source.amount || 50;
          this.dto.PaymentToken =  result.source.id;
          this.PlaceOrder();
          // if (result.source.status === 'SUCCESSFUL') {
          //   document.getElementsByClassName('text')[0].innerHTML = 'Flower Paid 💸, Thanks';
          // } else {
          //   document.getElementsByClassName('text')[0].innerHTML = 'Something went wrong. 😞';
          // }
        }
      });
    });
  }
}
