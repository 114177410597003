import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { SEOService } from './core/service/seo.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'KrivaJewelry';
  constructor(public router: Router,private activatedRoute: ActivatedRoute,private _seoService: SEOService){
    this.router.events.pipe(filter(event => event instanceof NavigationEnd),map(() => { const child = this.activatedRoute.firstChild; return child.snapshot.data;})
    ).subscribe((data: any) => {
      if(data && (Object.keys(data).length !== 0)) {
        //debugger
        //Updating website Title,Description,ogUrl dynamically change
        this._seoService.updateTitle(data['title']);
        this._seoService.updateOgUrl(data['ogUrl']);
        this._seoService.updateDescription(data['title'] +' - '+ data['description'])
      }
    });
  }
}
