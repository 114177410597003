<!-- <div class="banner-wrapper has_background">
  <img src="assets/images/banner-for-all2.jpg"
       class="img-responsive attachment-1920x447 size-1920x447" alt="img">
  <div class="banner-wrapper-inner">
      <h1 class="page-title">Contact</h1>
      <div role="navigation" aria-label="Breadcrumbs" class="breadcrumb-trail breadcrumbs">
          <ul class="trail-items breadcrumb">
              <li class="trail-item trail-begin"><a [routerLink]="['/index']"><span>Home</span></a></li>
              <li class="trail-item trail-end active"><span>Contact</span>
              </li>
          </ul>
      </div>
  </div>
</div> -->
<div class="site-main main-container no-sidebar" style="padding-top: 90px;">
  <div class="section-041">
      <div class="container">
          <div class="google-map">
              <iframe width="100%" height="500" id="gmap_canvas" src="https://maps.google.com/maps?q=university%20of%20san%20francisco&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
          </div>
      </div>
  </div>
  <div class="section-042">
      <div class="container">
          <div class="row">
              <div class="col-md-12 offset-xl-1 col-xl-10 col-lg-12">
                  <div class="row">
                      <div class="col-md-12">
                        <h4 class="az_custom_heading">kriva Jewelry</h4>
                        <div class="row">
                            <div class="col-sm-6">
                              <h4 class="az_custom_heading">Address</h4>
                              <p>712 E Broadway, Glendale, CA 91205<br>
                                P: (818) 500-8450</p>
                            </div>
                            <div class="col-sm-6">
                              <h4 class="az_custom_heading mb-2">Specialist Hours</h4>
                          <p>Monday-Friday 9am-5pm</p>
                              </div>
                        </div>




                      </div>
                      <!-- <div class="col-md-6">
                          <div role="form" class="wpcf7">
                              <form class="wpcf7-form">
                                  <p><label> Name *<br>
                                      <span class="wpcf7-form-control-wrap your-name">
                                          <input name="your-name" value="" size="40"
                                                 class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                 type="text"></span>
                                  </label></p>
                                  <p><label> Email *<br>
                                      <span class="wpcf7-form-control-wrap your-email">
                                          <input name="your-email" value="" size="40"
                                                 class="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                                                 type="email"></span>
                                  </label></p>
                                  <p><label> Your Message *<br>
                                      <span class="wpcf7-form-control-wrap your-message">
                                          <textarea name="your-message"
                                                    cols="40" rows="10"
                                                    class="wpcf7-form-control wpcf7-textarea"></textarea></span>
                                  </label></p>
                                  <p><input value="Send" class="wpcf7-form-control wpcf7-submit" type="submit"></p>
                              </form>
                          </div>
                      </div> -->
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
