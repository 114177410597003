<div id="carouselExampleIndicators" class="carousel slide my-carousel" data-ride="carousel">
  <ol class="carousel-indicators">
    <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
  </ol>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img class="d-block w-100" src="assets/images/slide11.jpg" alt="First slide">
      <div class="carousel-caption d-none d-md-block">
        <h5>Limited Colletion</h5>
                          <h1>Graceful</h1>
                          <h2>& Unique</h2>
                          <a href="#">Shop now</a>
      </div>
    </div>
    <div class="carousel-item">
      <img class="d-block w-100" src="assets/images/slide12.jpg" alt="Second slide">
      <div class="carousel-caption d-none d-md-block">
        <h5>Best Selling</h5>
          <h1>Royal</h1>
          <h2>& Dimond</h2>
          <a href="#">Shop now</a>
      </div>
    </div>
    <div class="carousel-item">
      <img class="d-block w-100" src="assets/images/slide13.jpg" alt="Third slide">
      <div class="carousel-caption d-none d-md-block">
         <h5>This Week Only</h5>
        <h1>Modern</h1>
        <h2>The Look</h2>
        <a href="#">Shop now</a>
      </div>
    </div>
  </div>
  <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>


<div class="fullwidth-template">

  <div class="section-003 section-002">
      <div class="container">
          <div class="row">
              <div class="col-md-12 col-lg-6">
                  <div class="mykriva-banner style-01 left-center">
                      <div class="banner-inner">
                          <figure class="banner-thumb">
                              <img src="assets/images/banner11.jpg"
                                   class="attachment-full size-full" alt="img"></figure>
                          <div class="banner-info ">
                              <div class="banner-content">
                                  <div class="title-wrap">
                                      <h6 class="title">
                                          <a target="_self" href="#">The Lookbook</a>
                                      </h6>
                                  </div>
                                  <div class="button-wrap">
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-md-12 col-lg-6">
                  <div class="mykriva-banner style-01 right-top">
                      <div class="banner-inner">
                          <figure class="banner-thumb">
                              <img src="assets/images/banner12.jpg"
                                   class="attachment-full size-full" alt="img"></figure>
                          <div class="banner-info ">
                              <div class="banner-content">
                                  <div class="title-wrap">
                                      <h6 class="title">
                                          <a target="_self" href="#">High Quality</a>
                                      </h6>
                                  </div>
                                  <div class="button-wrap">
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="mykriva-banner style-01 left-bottom">
                      <div class="banner-inner">
                          <figure class="banner-thumb">
                              <img src="assets/images/banner13.jpg"
                                   class="attachment-full size-full" alt="img"></figure>
                          <div class="banner-info ">
                              <div class="banner-content">
                                  <div class="title-wrap">
                                      <h6 class="title">
                                          <a target="_self" href="#">Fashion Set</a>
                                      </h6>
                                  </div>
                                  <div class="button-wrap">
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <div class="section-001">
      <div class="container">
          <div class="mykriva-heading style-01">
              <div class="heading-inner">
                  <h3 class="title">Best Seller<span></span></h3>
                  <div class="subtitle">Our products are perfect for every occasion
                  </div>
              </div>
          </div>
          <div class="mykriva-products style-02">

            <div class="row">
              <div class="col-sm-6 col-md-3" *ngFor="let item of listFeatured; index as i;">
                <div  class="product-item featured_products style-02 rows-space-30 post-34 product type-product status-publish has-post-thumbnail product_cat-light product_cat-new-arrivals product_tag-light product_tag-hat product_tag-sock first instock sale featured shipping-taxable product-type-grouped">
                  <div class="product-inner tooltip-top">
                      <div class="product-thumb">
                          <a class="thumb-link"
                              href="/{{removeSpace(item.Name)}}/details?id={{item.Id}}" tabindex="0">
                              <img class="img-responsive"
                              src="{{item.DefaultPhoto ? (item.DefaultPhoto) : 'assets/images/placeholder.png'}}"
                                   alt="Graceful Diamond" width="270" height="350">
                          </a>
                          <div class="flash">
                              <span class="onnew"><span class="text">New</span></span></div>
                          <a style="display: none;" href="/{{removeSpace(item.Name)}}/details?id={{item.Id}}" class="button yith-wcqv-button">Quick View</a></div>
                      <div class="product-info">
                          <div class="rating-wapper nostar">
                              <div class="star-rating"><span style="width:0%">Rated <strong
                                      class="rating">0</strong> out of 5</span></div>
                              <span class="review">(0)</span></div>
                          <h3 class="product-name product_title">
                              <a href="/{{removeSpace(item.Name)}}/details?id={{item.Id}}"
                                 tabindex="0">{{item.Name}}</a>
                          </h3>
                          <span class="price">
                              <span class="mykriva-Price-amount amount">
                                  <span class="mykriva-Price-currencySymbol">$</span>
                                  <span [innerHTML]="item.Price"></span>
                                  <span *ngIf="item.KeyDiamondRequired">
                                      <span style="font-size: 14px;">+</span>
                                  </span>
                              </span>
                          </span>
                      </div>
                  </div>
              </div>
              </div>
            </div>


          </div>
      </div>
  </div>

  <div class="section-001 section-046">
      <div class="container">
          <div class="mykriva-heading style-01">
              <div class="heading-inner">
                  <h3 class="title">New Arrival<span></span></h3>
                  <div class="subtitle">
                      Our products are perfect for every occasion.
                  </div>
              </div>
          </div>
          <div class="mykriva-products style-01">

            <div class="row">
                <div class="col-sm-6 col-md-3" *ngFor="let item of listNewArrival; index as i;">
                  <div  class="product-item recent-product style-01 rows-space-0 post-93 product type-product status-publish has-post-thumbnail product_cat-light product_cat-table product_cat-new-arrivals product_tag-table product_tag-sock first instock shipping-taxable purchasable product-type-simple  ">
                    <div class="product-inner tooltip-left">
                        <div class="product-thumb">
                            <a class="thumb-link"
                            href="/{{removeSpace(item.Name)}}/details?id={{item.Id}}" tabindex="i">
                                <img class="img-responsive"
                                     src="{{item.DefaultPhoto ? (item.DefaultPhoto) : 'assets/images/placeholder.png'}}"
                                     alt="item.Name" width="270" height="350">
                            </a>
                            <div class="flash">
                                <span class="onnew"><span class="text">New</span></span></div>

                        </div>
                        <div class="product-info equal-elem">
                            <h3 class="product-name product_title">
                                <a href="/{{removeSpace(item.Name)}}/details?id={{item.Id}}"
                                   tabindex="i">{{item.Name}}</a>
                            </h3>
                            <div class="rating-wapper nostar">
                                <div class="star-rating"><span style="width:0%">Rated <strong
                                        class="rating">0</strong> out of 5</span></div>
                                <span class="review">(0)</span></div>
                            <span class="price">
                                  <span class="mykriva-Price-amount amount">
                                      <span class="mykriva-Price-currencySymbol">$</span>
                                      <span [innerHTML]="item.Price"></span>
                                      <span *ngIf="item.KeyDiamondRequired">
                                          <span style="font-size: 14px;">+</span>
                                      </span>
                                  </span>
                              </span>
                        </div>
                    </div>
                </div>
                </div>
            </div>


          </div>
      </div>
  </div>
  <div class="section-038" style="display: none;">
      <div class="mykriva-banner style-07 left-center">
          <div class="banner-inner">
              <figure class="banner-thumb">
                  <img src="assets/images/banner28.jpg"
                       class="attachment-full size-full" alt="img"></figure>
              <div class="banner-info container">
                  <div class="banner-content">
                      <div class="title-wrap">
                          <div class="banner-label">
                              30 Nov - 03 Dec
                          </div>
                          <h6 class="title">
                              New Collection </h6>
                      </div>
                      <div class="cate">
                          50% Off / Selected items
                      </div>
                      <div class="button-wrap">
                          <div class="subtitle">
                              Mus venenatis habitasse leo malesuada lacus commodo
                          </div>
                          <a class="button" target="_self" href="#"><span>Shop now</span></a>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <div class="section-014" style="    margin-top: 50px;">
      <div class="container">
          <div class="row">
              <div class="col-md-6 col-lg-3">
                  <div class="mykriva-iconbox style-02">
                      <div class="iconbox-inner">
                          <div class="icon">
                              <span class="flaticon-startup"></span>
                          </div>
                          <div class="content">
                              <h4 class="title">Worldwide Delivery</h4>
                              <div class="desc">Ship to over 200 countries
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-md-6 col-lg-3">
                  <div class="mykriva-iconbox style-02">
                      <div class="iconbox-inner">
                          <div class="icon">
                              <span class="flaticon-padlock"></span>
                          </div>
                          <div class="content">
                              <h4 class="title">Safe Shipping</h4>
                              <div class="desc">Pay with the world’s most popular and secure payment methods.</div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-md-6 col-lg-3">
                  <div class="mykriva-iconbox style-02">
                      <div class="iconbox-inner">
                          <div class="icon">
                              <span class="flaticon-return-1"></span>
                          </div>
                          <div class="content">
                              <h4 class="title">365 Days Return</h4>
                              <div class="desc">Round-the-clock assistance for a smooth shopping experience.</div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-md-6 col-lg-3">
                  <div class="mykriva-iconbox style-02">
                      <div class="iconbox-inner">
                          <div class="icon">
                              <span class="flaticon-diamond-1"></span>
                          </div>
                          <div class="content">
                              <h4 class="title">Shop Confidence</h4>
                              <div class="desc">Our Buyer Protection covers your purchase from click to delivery.
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <div class="section-008">
      <div class="mykriva-instagram style-01">

        <div class="row">
            <div class="col-sm-6 col-md-3 col-xs-12 col-12">
              <a target="_blank" href="#" class="item"
              tabindex="0">
               <img class="img-responsive lazy" src="assets/images/insta1.jpg" alt="Home 01">
               <span class="instagram-info">
                        <span class="social-wrap">
                           <span class="social-info">1
                               <i class="pe-7s-chat"></i>
                           </span>
                           <span class="social-info">0
                               <i class="pe-7s-like2"></i>
                           </span>
                       </span>
                   </span>
           </a>
            </div>
            <div class="col-sm-6 col-md-3 col-xs-12 col-12">
              <a target="_blank" href="#" class="item"
              tabindex="0">
               <img class="img-responsive lazy" src="assets/images/insta2.jpg" alt="Home 01">
               <span class="instagram-info">
                        <span class="social-wrap">
                           <span class="social-info">1
                               <i class="pe-7s-chat"></i>
                           </span>
                           <span class="social-info">0
                               <i class="pe-7s-like2"></i>
                           </span>
                       </span>
                   </span>
           </a>
            </div>
            <div class="col-sm-6 col-md-3 col-xs-12 col-12">
              <a target="_blank" href="#" class="item"
              tabindex="0">
               <img class="img-responsive lazy" src="assets/images/insta3.jpg" alt="Home 01">
               <span class="instagram-info">
                        <span class="social-wrap">
                           <span class="social-info">1
                               <i class="pe-7s-chat"></i>
                           </span>
                           <span class="social-info">0
                               <i class="pe-7s-like2"></i>
                           </span>
                       </span>
                   </span>
           </a>
            </div>
            <div class="col-sm-6 col-md-3 col-xs-12 col-12">
              <a target="_blank" href="#" class="item"
              tabindex="0">
               <img class="img-responsive lazy" src="assets/images/insta4.jpg" alt="Home 01">
               <span class="instagram-info">
                        <span class="social-wrap">
                           <span class="social-info">1
                               <i class="pe-7s-chat"></i>
                           </span>
                           <span class="social-info">0
                               <i class="pe-7s-like2"></i>
                           </span>
                       </span>
                   </span>
           </a>
            </div>

        </div>

      </div>
  </div>
</div>
