<footer id="footer" class="footer style-01">
  <div class="section-001 section-009" style="display: none;">
      <div class="container">
          <div class="mykriva-newsletter style-01">
              <div class="newsletter-inner">
                  <div class="newsletter-info">
                      <div class="newsletter-wrap">
                          <h3 class="title">Newsletter</h3>
                          <h4 class="subtitle">Get Discount 30% Off</h4>
                          <p class="desc">Suspendisse netus proin eleifend fusce sollicitudin potenti vel magnis
                              nascetur</p>
                      </div>
                  </div>
                  <div class="newsletter-form-wrap">
                      <div class="newsletter-form-inner">
                          <input class="email email-newsletter" name="email" placeholder="Enter your email ..."
                                 type="email">
                          <a href="#" class="button btn-submit submit-newsletter">
                              <span class="text">Subscribe</span>
                          </a>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <div class="section-010">
      <div class="container">
          <div class="row">
              <div class="col-md-6">
                  <p>© Copyright 2020 <a href="#">Kriva Jewelry</a>. All Rights Reserved.</p>
              </div>
              <div class="col-md-6">
                  <div class="mykriva-socials style-01">
                      <div class="content-socials">
                          <ul class="socials-list">
                              <li>
                                  <a href="https://facebook.com/" target="_blank">
                                      <i class="fa fa-facebook"></i>
                                  </a>
                              </li>
                              <li>
                                  <a href="https://www.instagram.com/" target="_blank">
                                      <i class="fa fa-instagram"></i>
                                  </a>
                              </li>
                              <li>
                                  <a href="https://twitter.com/" target="_blank">
                                      <i class="fa fa-twitter"></i>
                                  </a>
                              </li>
                              <li>
                                  <a href="https://www.pinterest.com/" target="_blank">
                                      <i class="fa fa-pinterest-p"></i>
                                  </a>
                              </li>
                          </ul>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</footer>
<div class="footer-device-mobile">
  <div class="wapper">
      <div class="footer-device-mobile-item device-home">
          <a [routerLink]="['/index']">
        <span class="icon">
          <span class="fa fa-home"></span>
        </span>
              Home
          </a>
      </div>
      <div class="footer-device-mobile-item device-home device-wishlist">
          <a [routerLink]="['/index']">
        <span class="icon">
          <span class="flaticon-heart"></span>
        </span>
              Wishlist
          </a>
      </div>
      <div class="footer-device-mobile-item device-home device-cart">
          <a [routerLink]="['/cart']">
        <span class="icon">
          <span class="flaticon-shopping-bag-1"></span>
          <span class="count-icon">
            0
          </span>
        </span>
              <span class="text">Cart</span>
          </a>
      </div>
      <div class="footer-device-mobile-item device-home device-user">
          <a [routerLink]="['/profile']">
        <span class="icon">
          <span class="flaticon-profile"></span>
        </span>
              Account
          </a>
      </div>
  </div>
</div>
<a href="#" class="backtotop active">
  <i class="flaticon-right-arrow"></i>
</a>
