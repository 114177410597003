import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductDiamondService } from './product-diamond.service';
import { Utils } from '../../../core/common/utils'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Options, LabelType } from "@angular-slider/ngx-slider";
@Component({
  selector: 'app-product-diamond',
  templateUrl: './product-diamond.component.html',
  styleUrls: ['./product-diamond.component.css']
})
export class ProductDiamondComponent implements OnInit {

  id: number = 0;
  sid: any;

  isLoadingDetail: boolean = false;
  subscribeProductDetail: any;
  dto: any = {};

  isLoading: boolean = false;
  subscribeProductDiamond: any;
  list: any = [];
  selectedMetalFinenessId: number = 0;
  listPhoto: any = [];
  selectedDiamondId: number = 0;

  isLoadingProductRelated: boolean = false;
  subscribeProductRelated: any;
  listProductRelated: any = [];

  isLoadingSession: boolean = false;
  subscribeSession: any;
  sessionDTO: any = {};
  mountingPrice: number = 0;

  ds: number = 0;
  dc: string = "";
  dcl: string = "";
  minSize: any = null;
  maxSize: any = null;
  minPrice: any = null;
  maxPrice: any = null;
  fl: string = "";
  pn: number = 1;
  //ps: number = 50;
  needMoreData = true;

  isFilter: boolean = false;
  dcData: any = [];
  dclData: any = [];
  flData: any = [];
  dcVal: string = "";
  dclVal: string = "";
  flVal: string = "";
  priceList: any = [];
  sizeList: any = [];
  minSizeVal: any = null;
  maxSizeVal: any = null;
  minPriceVal: any = null;
  maxPriceVal: any = null;

  priceOptions: Options = {};
  sizeOptions: Options = {};

  constructor(
    private _ProductDiamondService: ProductDiamondService,
    private route: ActivatedRoute,
    private router: Router,
    //private _utils: Utils,
    private modalService: NgbModal
  ) {
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      this.sid = params['sid'];
      this.sessionDTO = {
        Id: this.sid,
        ProductId: this.id,
        CompanyId: Utils.CompanyId,
        CurrentStep: 1,
        CustomerId: null,
      };

    });
  }

  ngOnInit(): void {
    this.LoadProductSession(true);
   this.LoadProductDetail();


  }

  @HostListener("window:scroll", ["$event"]) onWindowScroll() {
    //In chrome and some browser scroll is given to body tag
    let pos = (document.documentElement.scrollTop || document.body.scrollTop);
    let triggerPos = document.documentElement.scrollHeight - 800;
    // pos/max will give you the distance between scroll bottom and and bottom of screen in percentage.
    if (pos > triggerPos) {
      //Do your action here
      //console.log(pos, triggerPos);
      this.LoadNextPage();
    }
  }

  LoadProductSession(isOnInit: Boolean = false) {
    this.isLoadingSession = true;
    if (this.subscribeSession) {
      this.subscribeSession.unsubscribe();
    }
    this.subscribeSession = this._ProductDiamondService
      .GetProductSession(this.id, this.sid)
      .subscribe(
        result => {
          this.isLoadingSession = false;
          if (result) {
            this.sessionDTO = result.jsonObj;
            console.log(this.sessionDTO);
          }
          if (this.sessionDTO != null) {
            if (isOnInit) {
              this.sessionDTO.KeyDiamond = null;
              this.sessionDTO.KeyDiamondId = null;
              this.sessionDTO.KeyDiamondPrice = null;
              this.SaveProductSession();
            }
            this.needMoreData = true;
            window.scrollTo(0, 0);
            this.LoadProductDiamond();
          }
          else {
            //redirect to home page
            this.router.navigate(['/']);
          }
        },
        error => {
          this.isLoadingSession = false;
        }
      );

  }

  LoadProductDetail() {
    this.isLoadingDetail = true;
    if (this.subscribeProductDetail) {
      this.subscribeProductDetail.unsubscribe();
    }
    this.subscribeProductDetail = this._ProductDiamondService
      .GetProductDetail(this.id)
      .subscribe(
        result => {
          this.isLoadingDetail = false;
          if (result) {
            this.dto = result.jsonObj;
            debugger
            if (this.dto != null) {
              this.ds = this.dto.KeyDiamondShapeId;
              this.maxPrice = this.dto.KeyDiamondMaxPrice;
              this.minPrice = this.dto.KeyDiamondMinPrice;
              this.maxSize = this.dto.KeyDiamondMaxSize;
              this.minSize = this.dto.KeyDiamondMinSize;
              this.minPriceVal = this.dto.KeyDiamondMinPrice;
              this.maxPriceVal = this.dto.KeyDiamondMaxPrice;
              this.minSizeVal = this.dto.KeyDiamondMinSize;
              this.maxSizeVal = this.dto.KeyDiamondMaxSize;
              this.dcData = this.dto.ListProductDiamondColorDTO;
              this.dcData.forEach((element: any) => {
                element.checked = false;
              });
              this.dclData = this.dto.ListProductDiamondClarityDTO;
              this.dclData.forEach((element: any) => {
                element.checked = false;
              });
              this.flData = this.dto.ListProductDiamondFluorescenceDTO;
              this.flData.forEach((element: any) => {
                element.checked = false;
              });

            }
            console.log(this.dto);
          }
        },
        error => {
          this.isLoadingDetail = false;
        }
      );
  }

  LoadRangSlider() {
    this.priceOptions = {
      floor: this.minPrice,
      ceil: this.maxPrice,
      translate: (value: number, label: LabelType): string => {
        switch (label) {
          case LabelType.Low:
            this.minPrice = value;
            return "<b>Min price:</b> $" + value;
          case LabelType.High:
            this.maxPrice = value;
            return "<b>Max price:</b> $" + value;
          default:
            return "$" + value;
        }
      }
    };
    this.sizeOptions = {
      floor: this.dto.KeyDiamondMinSize,
      ceil: this.dto.KeyDiamondMaxSize,
      step: 0.01,
      translate: (value1: number, label1: LabelType): string => {
        switch (label1) {
          case LabelType.Low:
            this.minSize = value1;
            return "<b>Min size:</b> " + value1;
          case LabelType.High:
            this.maxSize = value1;
            return "<b>Max size:</b> " + value1;
          default:
            return "" + value1;
        }
      }
    };
  }

  LoadNextPage() {
    if (!this.isLoading && this.needMoreData) {
      this.pn = this.pn + 1;
      this.LoadProductDiamond();
    }
  }

  LoadProductDiamond() {
    this.isLoading = true;
    if (this.subscribeProductDiamond) {
      this.subscribeProductDiamond.unsubscribe();
    }
    this.subscribeProductDiamond = this._ProductDiamondService
      .GetProductDiamond(this.id, this.ds, this.dc, this.dcl, this.minSize, this.maxSize, this.minPrice, this.maxPrice, this.fl, this.pn)
      .subscribe(
        result => {
          this.isLoading = false;
          if (result) {
            if (this.pn == 1) {
              this.list = result.jsonObj;
            }
            else {
              result.jsonObj.forEach((Element: any) => {
                this.list.push(Element);
              });
            }
            /*
            if(result.jsonObj == null || result.jsonObj.length == 0 || result.jsonObj.length < this.ps){
              this.needMoreData = false;
            }
            */
            console.log(this.list);
          }
        },
        error => {
          this.isLoading = false;
        }
      );
  }

  onChangeDiamond(item: any, $event: any, obj: any) {
    for (let i = 0; i < this.list.length; i++) {
      if (this.list[i].Id == item.Id) {
        this.list[i].selectedDiamond = true;
        this.list[i].disabled = true;
      }
      else {
        this.list[i].selectedDiamond = false;
        this.list[i].disabled = false;
      }
    }
    this.sessionDTO.KeyDiamondId = item.Id;
    this.SaveProductSession();
    console.log(item, $event, obj);
    console.log(this.selectedDiamondId);
  }

  GoToPaymentPage() {
    this.router.navigate(['/checkout'], { queryParams: { id: this.sessionDTO.ProductId, sid: this.sessionDTO.Id } });
  }


  SaveProductSession() {
    this.isLoadingSession = true;
    if (this.subscribeSession) {
      this.subscribeSession.unsubscribe();
    }
    this.subscribeSession = this._ProductDiamondService
      .SaveProductSession(this.sessionDTO)
      .subscribe(
        result => {
          this.isLoadingSession = false;
          if (result) {
            this.sessionDTO = result.jsonObj;
            this.mountingPrice = this.sessionDTO.MountingPrice;
            localStorage.setItem('product' + this.id, JSON.stringify(this.sessionDTO));
            console.log(this.sessionDTO);
          }
        },
        error => {
          this.isLoadingSession = false;
        }
      );
  }

  onChangeMetal(obj: any, event: any) {
    if (event.target.checked) {
      this.selectedMetalFinenessId = obj.MetalFinenessId;
      this.sessionDTO.MetalId = obj.MetalId;
      this.sessionDTO.MetalFinenessId = obj.MetalFinenessId;
      this.SaveProductSession();
    }
    console.log(this.selectedMetalFinenessId);
  }
  OnNext() {
  }


  // filterList()
  // {
  //   this.priceList= [];
  //   let actMaxPriceVal = (this.maxPriceVal * 80 / 100) / 5;
  //   for (var _i = 1; _i <= 5; _i++) {
  //     this.priceList.push((actMaxPriceVal * _i).toFixed(0));
  //   }
  //   this.sizeList = [];
  //   let actMaxSizeVal = (this.maxSizeVal * 80 / 100) / 5;
  //   for (var _j = 1; _j <= 5; _j++) {
  //     this.sizeList.push((actMaxSizeVal * _j).toFixed(2));
  //   }
  // }
  open(content: any) {
    //this.filterList();
    this.minSizeVal = this.dto.KeyDiamondMinSize;
    this.maxSizeVal = this.dto.KeyDiamondMaxSize;
    this.LoadRangSlider();

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => { }, (reason) => { });
  }
  fetchCheckedIDs() {
    let checkedColorIDs: any = []
    this.dcData.forEach((value: any) => {
      if (value.checked) {
        checkedColorIDs.push({ Id: value.DiamondColorId, Name: value.Name });
      }
    });
    this.dc = checkedColorIDs.map(function (elem: any) { return elem.Id; }).join(",").toString();
    this.dcVal = checkedColorIDs.map(function (elem: any) { return elem.Name; }).join(",").toString();
    let checkedClarityIDs: any = []
    this.dclData.forEach((value: any) => {
      if (value.checked) {
        checkedClarityIDs.push({ Id: value.DiamondClarityId, Name: value.Name });
      }
    });
    this.dcl = checkedClarityIDs.map(function (elem: any) { return elem.Id; }).join(",").toString();
    this.dclVal = checkedClarityIDs.map(function (elem: any) { return elem.Name; }).join(",").toString();
    let checkedFluorescenceIDs: any = [];
    this.flData.forEach((value: any) => {
      if (value.checked) {
        checkedFluorescenceIDs.push({ Id: value.DiamondFluorescenceId, Name: value.Name });
      }
    });
    this.fl = checkedFluorescenceIDs.map(function (elem: any) { return elem.Id; }).join(",").toString();
    this.flVal = checkedFluorescenceIDs.map(function (elem: any) { return elem.Name; }).join(",").toString();
  }
  OnchangeCheckColor(obj: any) {
    this.dcData.filter((value: any) => {
      value.checked = (value.DiamondColorId == obj.DiamondColorId) ? !value.checked : value.checked;
    });
  }
  OnchangeCheckClarity(obj: any) {
    this.dclData.filter((value: any) => {
      value.checked = (value.DiamondClarityId == obj.DiamondClarityId) ? !value.checked : value.checked;
    });
  }
  OnchangeCheckFluorescence(obj: any) {
    this.flData.filter((value: any) => {
      value.checked = (value.DiamondFluorescenceId == obj.DiamondFluorescenceId) ? !value.checked : value.checked;
    });
  }
  // OnchangeMinPrice(obj: any) {
  //   this.minPrice = (obj.target.value && obj.target.value != '') ? obj.target.value : null;
  // }
  // OnchangeMaxPrice(obj: any) {
  //   this.maxPrice = (obj.target.value && obj.target.value != '') ? obj.target.value : null;
  // }
  // OnchangeMinSize(obj: any) {
  //   this.minSize = (obj.target.value && obj.target.value != '') ? obj.target.value : null;
  // }
  // OnchangeMaxSize(obj: any) {
  //   this.maxSize = (obj.target.value && obj.target.value != '') ? obj.target.value : null;
  // }
  applyFilter() {
    this.isFilter = true;
    this.fetchCheckedIDs();
    this.needMoreData = true;
    window.scrollTo(0, 0);
    //  this.minSize = this.minSizeVal;
    //  this.maxSize = this.maxSizeVal;
    //  this.minPrice = this.minPriceVal;
    //  this.maxPrice = this.maxPriceVal;
    this.pn =1;
    this.LoadProductDiamond();
  }
  clearFilter() {
    this.minSizeVal = null;
    this.maxSizeVal = null;
    this.minPriceVal = null;
    this.maxPriceVal = null;
    this.isFilter = false;
    this.ds = 0;
    this.dc = "";
    this.dcl = "";
    // this.minSize = this.minSizeVal;
    // this.maxSize = this.maxSizeVal;
    // this.minPrice = this.minPriceVal;
    // this.maxPrice = this.maxPriceVal;
    this.fl = "";
    this.pn = 1;
    this.dcVal = "";
    this.dclVal = "";
    this.flVal = "";
    this.dcData.forEach((element: any) => { element.checked = false; });
    this.dclData.forEach((element: any) => { element.checked = false; });
    this.flData.forEach((element: any) => { element.checked = false; });
    this.needMoreData = true;
    window.scrollTo(0, 0);

    this.LoadProductDiamond();
  }




}
