import { Injectable, Injector } from '@angular/core';
import { ApiService } from '../../../core/service/api.service' //'/app/core/service/api.service';
import { QueryParameter, AppResponse } from '../../../core/service/QueryParameter' //'app/shared/modal/QueryParameter';
import { environment } from '../../../../environments/environment' //'  environment/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductListService {
  private apiurl: string = environment.apiUrl;
  private GetProductListUrl: string = this.apiurl + "Product";
  private GetDiamondShapeListUrl: string = this.apiurl + "KeyDiamondShape";
  private GetSubCategoryListUrl: string = this.apiurl + "SubCategory";
  private GetGenderListUrl: string = this.apiurl + "Gender";

  private GetDashboardCountUrl: string = this.apiurl + "OrderCountDashboard";
  private GetOrderListUrl: string = this.apiurl + "Order/GetList/";
  
  constructor(private injector: Injector, private apiService: ApiService) { }
  public get ApiService(): ApiService {
    if (!this.apiService) {
      this.apiService = this.injector.get(ApiService);
    }
    return this.apiService;
  }

  public GetProductList(cId: any, sCId: string, gId: string, mId: any, mFId: any, kDSId: any, pn: number, ps: number){
    let url = this.GetProductListUrl + "?cid="+cId + "&sCId="+sCId + "&gId="+gId + "&mId="+mId + "&mFId="+mFId + "&kDSId="+kDSId + "&pN="+pn + "&pS="+ps;
    return this.ApiService.get<AppResponse<any>>(url);
  }

  public GetDiamondShapeList(){
    let url = this.GetDiamondShapeListUrl;
    return this.ApiService.get<AppResponse<any>>(url);
  }

  public GetSubCategoryList(cId:number){
    let url = this.GetSubCategoryListUrl + "/"+cId;
    return this.ApiService.get<AppResponse<any>>(url);
  }

  public GetGenderList(){
    let url = this.GetGenderListUrl;
    return this.ApiService.get<AppResponse<any>>(url);
  }  

  public GetDashboardCount(queryParameter: QueryParameter) {
    const parameter = { q: queryParameter };
    return this.ApiService.getList<AppResponse<any>>(
      this.GetDashboardCountUrl,
      parameter
    );
  }

  public GetOrderList(queryParameter: QueryParameter) {
    const parameter = { q: queryParameter };
    return this.ApiService.getList<AppResponse<any>>(
      this.GetOrderListUrl,
      parameter
    );
  }


}

