import { Injectable, Injector } from '@angular/core';
import { ApiService } from '../../../core/service/api.service' //'/app/core/service/api.service';
import { QueryParameter, AppResponse } from '../../../core/service/QueryParameter' //'app/shared/modal/QueryParameter';
import { environment } from '../../../../environments/environment' //'  environment/environment';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  private apiurl: string = environment.apiUrl;
  private GetProductNewArrivalUrl: string = this.apiurl + "ProductNewArrival";
  private GetProductFeaturedUrl: string = this.apiurl + "ProductFeatured";

  
  constructor(private injector: Injector, private apiService: ApiService) { }
  public get ApiService(): ApiService {
    if (!this.apiService) {
      this.apiService = this.injector.get(ApiService);
    }
    return this.apiService;
  }

  public GetProductNewArrival(){
    let url = this.GetProductNewArrivalUrl;
    return this.ApiService.get<AppResponse<any>>(url);
  }

  public GetProductFeatured(){
    let url = this.GetProductFeaturedUrl;
    return this.ApiService.get<AppResponse<any>>(url);
  }


}

