import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductDetailService } from './product-detail.service';
import {Utils} from '../../../core/common/utils'

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.css']
})
export class ProductDetailComponent implements OnInit {

  id:number = 0;
  isLoading : boolean = false;
  subscribeProductDetail: any;
  dto: any = [];
  selectedMetalFinenessId: number=0;
  listPhoto: any = [];

  isLoadingProductRelated : boolean = false;
  subscribeProductRelated: any;
  listProductRelated: any = [];

  isLoadingSession : boolean = false;
  subscribeSession: any;
  sessionDTO: any = {};
  mountingPrice: number = 0;

  constructor(
    private _ProductDetailService: ProductDetailService,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title, 
    private metaService: Meta
    //private _utils: Utils
  ) {
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      this.sessionDTO = {
        ProductId: this.id,
        CompanyId: Utils.CompanyId,
        CurrentStep: 1,
        CustomerId: null,
      };
  });
  }

  ngOnInit(): void {
    console.log('product'+this.id);
    try{
      const obj = localStorage.getItem('product'+this.id);
      console.log(obj);
      if(obj != null){
        this.sessionDTO = JSON.parse(obj);
      }
    }
    catch{}
    this.LoadProductDetail();
    this.LoadProductRelated();
  }

  LoadProductDetail(){
    this.isLoading = true;
    if (this.subscribeProductDetail) {
        this.subscribeProductDetail.unsubscribe();
    }
    this.subscribeProductDetail = this._ProductDetailService
        .GetProductDetail(this.id)
        .subscribe(
            result => {
                this.isLoading = false;
                //debugger
                if (result) {
                    this.dto = result.jsonObj;
                    if(this.dto != null){
                      this.SetupPageData();
                      this.selectedMetalFinenessId = this.dto.DefaultMetalFinenessId;
                      this.mountingPrice = this.dto.Price;
                      this.sessionDTO.ProductId = this.dto.Id;
                      this.sessionDTO.MetalId = this.dto.DefaultMetalId;
                      this.sessionDTO.MetalFinenessId = this.dto.DefaultMetalFinenessId;
                      this.setPhotoList(this.dto.DefaultMetalId);
                      this.SaveProductSession();
                    }
                    console.log(this.dto);
                }
            },
            error => {
                this.isLoading = false;
            }
        );
  }

  SetupPageData(){
    this.titleService.setTitle(this.dto.Name + " | Kriva Jewelry");
    this.metaService.addTags([
      {name: 'keywords', content: 'kriva jewelry, diamond jewelry, wedding, engagement'},
      {name: 'description', content: this.dto.Description},
      {name: 'robots', content: 'index, follow'}
    ]);

  }

  SaveProductSession(){
    this.isLoadingSession = true;
    if (this.subscribeSession) {
      this.subscribeSession.unsubscribe();
    }
    this.subscribeSession = this._ProductDetailService
        .SaveProductSession(this.sessionDTO)
        .subscribe(
            result => {
                this.isLoading = false;
                if (result) {
                    this.sessionDTO = result.jsonObj;
                    this.mountingPrice = this.sessionDTO.MountingPrice;
                    localStorage.setItem('product'+this.id, JSON.stringify(this.sessionDTO));
                    console.log(this.sessionDTO);
                }
            },
            error => {
                this.isLoading = false;
            }
    );
  }

  LoadProductRelated(){
    this.isLoadingProductRelated = true;
    if (this.subscribeProductRelated) {
        this.subscribeProductRelated.unsubscribe();
    }
    this.subscribeProductRelated = this._ProductDetailService
        .GetProductRelatedList(this.id)
        .subscribe(
            result => {
                this.isLoadingProductRelated = false;
                if (result) {
                    this.listProductRelated = result.jsonObj;
                    console.log(this.listProductRelated);
                }
            },
            error => {
                this.isLoadingProductRelated = false;
            }
        );

  }


  onChangeMetal(obj:any, event: any){
    if(event.target.checked){
      this.selectedMetalFinenessId = obj.MetalFinenessId;
      this.setPhotoList(obj.MetalId);
      this.sessionDTO.MetalId = obj.MetalId;
      this.sessionDTO.MetalFinenessId = obj.MetalFinenessId;
      this.SaveProductSession();
    }
    console.log(this.selectedMetalFinenessId);
  }

  setPhotoList(metalId: number){
    console.log(metalId);
    let aryPhoto: any = [];
    this.dto.ListProductPhotoDTO.forEach(function (value:any) {
      if(value.MetalId == metalId){
        aryPhoto.push(value.Url);
      }
    });
    this.listPhoto = aryPhoto;
    console.log(this.listPhoto);
  }

  GoToDiamondPage(){
    this.router.navigate(['/diamond'],{queryParams:{ id: this.sessionDTO.ProductId, sid: this.sessionDTO.Id}});
  }

  GoToPaymentPage(){
    this.router.navigate(['/checkout'],{queryParams:{ id: this.sessionDTO.ProductId, sid: this.sessionDTO.Id}});
  }

  removeSpace = function(text:string) {
    var str = text.replace(/\s+/g, '-');
    return str.toLowerCase();
  };

}
