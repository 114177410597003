import { Injectable, Injector } from '@angular/core';
import { ApiService } from '../../../core/service/api.service' //'/app/core/service/api.service';
import { QueryParameter, AppResponse } from '../../../core/service/QueryParameter' //'app/shared/modal/QueryParameter';
import { environment } from '../../../../environments/environment' //'  environment/environment';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {
  private apiurl: string = environment.apiUrl;

  private GetProductSessionUrl: string = this.apiurl + "WebsiteProductSession";
  private PlaceOrderUrl: string = this.apiurl + "websiteorder";

  constructor(private injector: Injector, private apiService: ApiService) { }
  public get ApiService(): ApiService {
    if (!this.apiService) {
      this.apiService = this.injector.get(ApiService);
    }
    return this.apiService;
  }

  public GetProductSession(productId: any, id: any){
    let url = this.GetProductSessionUrl + "?id="+id + "&productId="+ productId;
    return this.ApiService.get<AppResponse<any>>(url);
  }

  public PlaceOrder(dto:any){
    let url = this.PlaceOrderUrl;
    return this.ApiService.post<AppResponse<any>>(url, dto);
  }
  public OnlinePlaceOrder(dto:any){
    let url = this.PlaceOrderUrl;
    return this.ApiService.put<AppResponse<any>>(url, dto);
  }
}

