import { Injectable, Injector } from '@angular/core';
import { ApiService } from '../../../core/service/api.service' //'/app/core/service/api.service';
import { QueryParameter, AppResponse } from '../../../core/service/QueryParameter' //'app/shared/modal/QueryParameter';
import { environment } from '../../../../environments/environment' //'  environment/environment';

@Injectable({
  providedIn: 'root'
})
export class OrderConfirmationService {
  private apiurl: string = environment.apiUrl;
  private GetOrderDetailUrl: string = this.apiurl + "websiteorder";
  
  constructor(private injector: Injector, private apiService: ApiService) { }
  public get ApiService(): ApiService {
    if (!this.apiService) {
      this.apiService = this.injector.get(ApiService);
    }
    return this.apiService;
  }

  public GetOrderDetail(id: number, pid: number, sid: any){
    let url = this.GetOrderDetailUrl + "?id="+id + "&pid="+pid + "&sid="+sid;
    return this.ApiService.get<AppResponse<any>>(url);
  }

}

