// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,

  apiUrl: 'https://api.krivajewelry.com/api/',
  token: '710852ab-39b8-11eb-81b6-74867ad5b9c4:1008',
  companyId: '1002',
  stripeAPIKey :'pk_test_51HukjnDaYrph1RB2WCPrJC4onqvaC0B9fuF4vd4reEpqsUZ9ES5QWUWUrsQ77qeLuMQUBH76fcLWJUxb7lOEpKHj006ieYtO4n'
  // apiUrl: 'http://localhost:12674/api/',
  // token: '4d116d86-68b3-45c5-9e89-2a25c9109098:1002',
  // companyId: '1001',
  // stripeAPIKey :'pk_test_51HukjnDaYrph1RB2WCPrJC4onqvaC0B9fuF4vd4reEpqsUZ9ES5QWUWUrsQ77qeLuMQUBH76fcLWJUxb7lOEpKHj006ieYtO4n'
  /*
  apiUrl: 'http://api.krivajewelry.com/api/',
  token: '710852ab-39b8-11eb-81b6-74867ad5b9c4:1008',
  companyId: '1002',
  */
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
