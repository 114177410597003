import { Component, OnInit } from '@angular/core';
import { HomeService } from './home.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  isLoadingNewArrival : boolean = false;
  subscribeNewArrival: any;
  listNewArrival: any = [];

  isLoadingFeatured : boolean = false;
  subscribeFeatured: any;
  listFeatured: any = [];

  constructor(
    private _HomeService: HomeService
  ) { }

  ngOnInit(): void {
    this.LoadProductNewArrival();
    this.LoadProductFeatured();
  }

  LoadProductNewArrival(){
    this.isLoadingNewArrival = true;
    if (this.subscribeNewArrival) {
        this.subscribeNewArrival.unsubscribe();
    }
    this.subscribeNewArrival = this._HomeService
      .GetProductNewArrival()
      .subscribe(
          result => {
            this.isLoadingNewArrival = false;
            if (result) {
              this.listNewArrival = result.jsonObj;
              console.log(this.listNewArrival);
            }
          },
          error => {
            this.isLoadingNewArrival = false;
          }
    );
  }

  LoadProductFeatured(){
    this.isLoadingFeatured = true;
    if (this.subscribeFeatured) {
        this.subscribeFeatured.unsubscribe();
    }
    this.subscribeFeatured = this._HomeService
      .GetProductFeatured()
      .subscribe(
          result => {
            this.isLoadingFeatured = false;
            if (result) {
              this.listFeatured = result.jsonObj;
              console.log(this.listFeatured);
            }
          },
          error => {
            this.isLoadingFeatured = false;
          }
    );
  }
  
  removeSpace = function(text:string) {
    var str = text.replace(/\s+/g, '-');
    return str.toLowerCase();
  };

}
