import { Component, OnInit, HostListener } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductListService} from '../product-list/product-list.service'

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css']
})
export class ProductListComponent implements OnInit {

  isLoading : boolean = false;
  subscribeProductList: any;
  List: any = [];

  isSubCategoryLoading: boolean = false;
  subscribeSubCategoryList: any;
  SubCategoryList: any = [];
  selectedSubCat: any = [];

  isDiamondShapeLoading: boolean = false;
  subscribeDiamondShapeList: any;
  DiamondShapeList: any = [];
  selectedDiaShape: any = [];

  isGenderLoading: boolean = false;
  subscribeGenderList: any;
  GenderList: any = [];
  selectedGender: any = [];

  cId: any;
  sCId: string = "";
  gId: string = "";
  mId: any;
  mFId: any;
  kDSId: any;
  pn: number = 1;
  ps: number = 50;
  needMoreData = true;

  constructor(
    private _ProductListService: ProductListService,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title, 
    private metaService: Meta
  ) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.cId = params['cid'];
      if(this.cId != null && this.cId >0){
        this.SetupPageData();
        this.LoadProductList();
      }
    });
    this.LoadSubCategoryList();
    this.LoadDiamondShapeList();
    this.LoadGenderList();
  }

  SetupPageData(){
    let title = "";
    let description = "";
    switch(this.cId){
      case "1":
        title = "Ring";
        description = "ring";
        break;
      case "2":
        title = "Earring";
        description = "earring";        
        break;
      case "3":
        title = "Necklace";
        description = "necklace";        
        break;
      case "4":
        title = "Bracelet";
        description = "bracelet";
        break;
    }
    this.titleService.setTitle(title + " | Kriva Jewelry");
    this.metaService.addTags([
      {name: 'keywords', content: 'kriva jewelry, diamond jewelry, wedding, engagement'},
      {name: 'description', content: description},
      //{name: 'robots', content: 'index, follow'}
    ]);
  }  

  @HostListener("window:scroll", ["$event"]) onWindowScroll() {
    //In chrome and some browser scroll is given to body tag
    let pos = (document.documentElement.scrollTop || document.body.scrollTop);
    let triggerPos = document.documentElement.scrollHeight-800;
    // pos/max will give you the distance between scroll bottom and and bottom of screen in percentage.
    if(pos > triggerPos )   {
      //Do your action here
      //console.log(pos, triggerPos);
      this.LoadNextPage();
    }
  }
  
  removeSpace = function(text:string) {
    var str = text.replace(/\s+/g, '-');
    return str.toLowerCase();
  };
  
  LoadSubCategoryList(){
    this.isSubCategoryLoading = true;
    if (this.subscribeSubCategoryList) {
        this.subscribeSubCategoryList.unsubscribe();
    }
    this.subscribeSubCategoryList = this._ProductListService
      .GetSubCategoryList(this.cId)
      .subscribe(
          result => {
            this.isSubCategoryLoading = false;
            if (result) {
              this.SubCategoryList = result.jsonObj;
              console.log(this.SubCategoryList);
            }
          },
          error => {
            this.isSubCategoryLoading = false;
          }
    );
  }

  LoadDiamondShapeList(){
    this.isDiamondShapeLoading = true;
    if (this.subscribeDiamondShapeList) {
        this.subscribeDiamondShapeList.unsubscribe();
    }
    this.subscribeDiamondShapeList = this._ProductListService
      .GetDiamondShapeList()
      .subscribe(
          result => {
            this.isDiamondShapeLoading = false;
            if (result) {
              this.DiamondShapeList = result.jsonObj;
              console.log(this.DiamondShapeList);
            }
          },
          error => {
            this.isDiamondShapeLoading = false;
          }
    );
  }

  LoadGenderList(){
    this.isGenderLoading = true;
    if (this.subscribeGenderList) {
        this.subscribeGenderList.unsubscribe();
    }
    this.subscribeGenderList = this._ProductListService
      .GetGenderList()
      .subscribe(
          result => {
            this.isGenderLoading = false;
            if (result) {
              this.GenderList = result.jsonObj;
              console.log(this.GenderList);
            }
          },
          error => {
            this.isGenderLoading = false;
          }
    );
  }



  LoadProductList(){
    this.isLoading = true;
    if (this.subscribeProductList) {
        this.subscribeProductList.unsubscribe();
    }
    this.subscribeProductList = this._ProductListService
        .GetProductList(this.cId, this.sCId, this.gId, this.mId, this.mFId, this.kDSId, this.pn, this.ps)
        .subscribe(
            result => {
                this.isLoading = false;
                if (result) {
                  if(this.pn == 1){
                    this.List = result.jsonObj;
                  }
                  else{
                    result.jsonObj.forEach((Element: any) => {
                      this.List.push(Element);  
                    });
                  }
                  if(result.jsonObj == null || result.jsonObj.length == 0 || result.jsonObj.length < this.ps){
                    this.needMoreData = false;
                  }
                  console.log(this.List);
                }
            },
            error => {
                this.isLoading = false;
            }
        );
  }

  LoadNextPage(){
    if(!this.isLoading && this.needMoreData){
      this.pn = this.pn + 1;
      this.LoadProductList();
    }
  }

  onChangeSubCategory(obj:any, event: any){
    if(event.target.checked){
      this.selectedSubCat.push(obj.Id);
    }
    else{
      for (let sub of this.selectedSubCat) {
        if (sub === obj.Id) {
            this.selectedSubCat.splice(this.selectedSubCat.indexOf(sub), 1);
            break;
        }
      }
    }
    if(this.selectedSubCat.length > 0){
      this.sCId = this.selectedSubCat.join(",");
    }
    else {
      this.sCId = "";
    }
    this.pn = 1;
    this.ps = 50;
    this.needMoreData = true;
    window.scrollTo(0, 0);
    this.LoadProductList();
  }


  onChangeDiamondShape(obj:any, event: any){
    console.log(obj);
    if(event.target.checked){
      this.selectedDiaShape.push(obj.Id);
    }
    else{
      for (let ds of this.selectedDiaShape) {
        if (ds === obj.Id) {
            this.selectedDiaShape.splice(this.selectedDiaShape.indexOf(ds), 1);
            break;
        }
      }
    }
    if(this.selectedDiaShape.length > 0){
      this.kDSId = this.selectedDiaShape.join(",");
    }
    else {
      this.kDSId = "";
    }
    this.pn = 1;
    this.ps = 50;
    this.needMoreData = true;
    window.scrollTo(0, 0);
    this.LoadProductList();
  }

  onChangeGender(obj:any, event: any){
    if(event.target.checked){
      this.selectedGender.push(obj.Id);
    }
    else{
      for (let g of this.selectedGender) {
        if (g === obj.Id) {
            this.selectedGender.splice(this.selectedGender.indexOf(g), 1);
            break;
        }
      }
    }
    if(this.selectedGender.length > 0){
      this.gId = this.selectedGender.join(",");
    }
    else {
      this.gId = "";
    }
    this.pn = 1;
    this.ps = 50;
    this.needMoreData = true;
    window.scrollTo(0, 0);
    this.LoadProductList();
  }



}
