<div class="banner-wrapper">
  <div class="banner-wrapper-inner">
      <nav class="mykriva-breadcrumb"><a [routerLink]="['/index']">Home</a><i class="fa fa-angle-right"></i><a href="#">Shop</a>
          <i class="fa fa-angle-right"></i>Single Product
      </nav>
  </div>
</div>
<div class="single-thumb-vertical main-container shop-page no-sidebar">
  <div class="container">
      <div class="row">
          <div class="main-content col-md-12">
              <div class="mykriva-notices-wrapper"></div>
              <div id="product-27"
                   class="post-27 product type-product status-publish has-post-thumbnail product_cat-table product_cat-new-arrivals product_cat-lamp product_tag-table product_tag-sock first instock shipping-taxable purchasable product-type-variable has-default-attributes">
                  <div class="main-contain-summary">
                      <div class="contain-left has-gallery">
                          <div class="single-left">
                            <div class="containerr">
                              <div class="row">
                                  <div class="col-md-12">
                                      <div id="custCarousel" class="carousel slide" data-ride="carousel" align="center">
                                        <div class="product-detail-slider">
                                          <ol class="control-thumbs carousel-indicators" id="carouselExampleIndicators">
                                              <li *ngFor="let item of listPhoto; index as i;" class="{{ (i == 0) ? 'active' : '' }}" id="{{ 'wave' + i }}" attr.data-slide-to="{{i}}"  data-target="#custCarousel">
                                                  <img alt="img" [src]="item">
                                              </li>
                                          </ol>
                                          <!-- slides -->
                                          <div class="img-slider">
                                            <div class="carousel-inner">
                                              <div class="carousel-item {{ i == 0 ? 'active' : '' }}" *ngFor="let item of listPhoto; index as i;" >
                                                <!-- <img [src]="item" alt="Hills">  -->
                                                <lib-ngx-image-zoom
                                                    [thumbImage]=item
                                                    [fullImage]=item
                                                    [magnification]="0.8"
                                                    [enableLens]="true"
                                                    [lensWidth]="150"
                                                    [lensHeight]="150"
                                                ></lib-ngx-image-zoom>
                                              </div>
                                          </div>
                                          </div>
                                        </div>
                                      </div>
                                  </div>
                              </div>
                          </div>


                          </div>
                          <div class="summary entry-summary">
                              <div class="flash">
                                  <span class="onnew"><span class="text">New</span></span></div>
                              <h1 class="product_title entry-title" [innerHTML]="dto.Name"></h1>
                              <p class="price">
                                  <span class="mykriva-Price-amount amount">
                                      <span class="mykriva-Price-currencySymbol">$</span>
                                      <span *ngIf="mountingPrice > 0" [innerHTML]="mountingPrice"></span>
                                      <span *ngIf="dto.KeyDiamondRequired">
                                          <span style="font-size: 10px;"> (without center stone)</span>
                                      </span>
                                    </span>
                                </p>
                              <p class="stock in-stock">
                                  Availability: <span> In stock</span>
                              </p>
                              <div class="mykriva-product-details__short-description">
                                  <p *ngIf="dto.Description != null" [innerHTML]="dto.Description"></p>
                                  <ul style="display: none;">
                                      <li>Water-resistant fabric with soft lycra detailing inside</li>
                                      <li>CLean zip-front, and three piece hood</li>
                                      <li>Subtle branding and diagonal panel detail</li>
                                  </ul>
                              </div>
                              <form class="variations_form cart">
                                  <table class="variations">
                                      <tbody>
                                      <tr>
                                          <td class="label"><label>Metal</label></td>
                                          <td class="value">
                                              <select title="box_style" data-attributetype="box_style"
                                                      data-id="pa_color"
                                                      class="attribute-select " name="attribute_pa_color"
                                                      data-attribute_name="attribute_pa_color"
                                                      data-show_option_none="yes">
                                                  <option data-type="" data-pa_color="" value="">Choose an option
                                                  </option>
                                                  <option data-width="30" data-height="30" data-type="color"
                                                          data-pa_color="#3155e2" value="blue"
                                                          class="attached enabled">Blue
                                                  </option>
                                                  <option data-width="30" data-height="30" data-type="color"
                                                          data-pa_color="#ffe59e" value="pink"
                                                          class="attached enabled">Pink
                                                  </option>
                                                  <option data-width="30" data-height="30" data-type="color"
                                                          data-pa_color="#b6b8ba" value="red"
                                                          class="attached enabled">Red
                                                  </option>
                                                  <option data-width="30" data-height="30" data-type="color"
                                                          data-pa_color="#e8e120" value="yellow"
                                                          class="attached enabled">Yellow
                                                  </option>
                                              </select>
                                              <div class="data-val attribute-pa_color"
                                                   data-attributetype="box_style">
                                                    <label *ngFor="let item of dto.ListProductMetalFinenessDTO; index as i;">
                                                        <span [ngSwitch]="item.MetalFinenessId">
                                                            <span *ngSwitchCase="1">
                                                                <span class="metalSelection gold">
                                                                    <input type="radio" name="metal" (change)="onChangeMetal(item,$event)">
                                                                    <span class="change-value metalValue">14K</span>
                                                                </span>
                                                                <div *ngIf="selectedMetalFinenessId == item.MetalFinenessId" class="metalSelected gold"></div>
                                                            </span>
                                                            <span *ngSwitchCase="2">
                                                                <span class="metalSelection gold">
                                                                    <input type="radio" name="metal" (change)="onChangeMetal(item,$event)">
                                                                    <span class="change-value metalValue">18K</span>
                                                                </span>
                                                                <div *ngIf="selectedMetalFinenessId == item.MetalFinenessId" class="metalSelected gold"></div>
                                                            </span>
                                                            <span *ngSwitchCase="3">
                                                                <span class="metalSelection whiteGold">
                                                                    <input type="radio" name="metal" (change)="onChangeMetal(item,$event)">
                                                                    <span class="change-value metalValue">14K</span>
                                                                </span>
                                                                <div *ngIf="selectedMetalFinenessId == item.MetalFinenessId" class="metalSelected whiteGold"></div>
                                                            </span>
                                                            <span *ngSwitchCase="4">
                                                                <span class="metalSelection whiteGold">
                                                                    <input type="radio" name="metal" (change)="onChangeMetal(item,$event)">
                                                                    <span class="change-value metalValue">18K</span>
                                                                </span>
                                                                <div *ngIf="selectedMetalFinenessId == item.MetalFinenessId" class="metalSelected whiteGold"></div>
                                                            </span>
                                                            <span *ngSwitchCase="5">
                                                                <span class="metalSelection roseGold">
                                                                    <input type="radio" name="metal" (change)="onChangeMetal(item,$event)">
                                                                    <span class="change-value metalValue">14K</span>
                                                                </span>
                                                                <div *ngIf="selectedMetalFinenessId == item.MetalFinenessId" class="metalSelected roseGold"></div>
                                                            </span>
                                                            <span *ngSwitchCase="6">
                                                                <span class="metalSelection roseGold">
                                                                    <input type="radio" name="metal" (change)="onChangeMetal(item,$event)">
                                                                    <span class="change-value metalValue">18K</span>
                                                                </span>
                                                                <div *ngIf="selectedMetalFinenessId == item.MetalFinenessId" class="metalSelected roseGold"></div>
                                                            </span>
                                                            <span *ngSwitchCase="7">
                                                                <span class="metalSelection whiteGold">
                                                                    <input type="radio" name="metal" (change)="onChangeMetal(item,$event)">
                                                                    <span class="change-value metalValue">Pt</span>
                                                                </span>
                                                                <div *ngIf="selectedMetalFinenessId == item.MetalFinenessId" class="metalSelected whiteGold"></div>
                                                            </span>
                                                        </span>
                                                    </label>
                                              </div>
                                              <a class="reset_variations" href="#" style="visibility: hidden;">Clear</a>
                                            </td>
                                      </tr>
                                      </tbody>
                                  </table>
                                  <div class="single_variation_wrap">
                                      <div class="mykriva-variation single_variation"></div>
                                      <div class="mykriva-variation-add-to-cart variations_button ">
                                        <a *ngIf="!dto.KeyDiamondRequired" type="submit"
                                            (click)="GoToPaymentPage()"
                                            class="single_add_to_cart_button button alt mykriva-variation-selection-needed">
                                              Check Out
                                        </a>
                                        <a *ngIf="dto.KeyDiamondRequired"
                                            (click)="GoToDiamondPage()"
                                            class="single_add_to_cart_button button alt mykriva-variation-selection-needed">
                                            Select Center Stone
                                        </a>
                                      </div>
                                  </div>
                              </form>

                              <div class="clear"></div>

                              <div class="product_meta">
                                  <div class="wcml-dropdown product wcml_currency_switcher">
                                      <ul>
                                          <li class="wcml-cs-active-currency">
                                              <a class="wcml-cs-item-toggle">USD</a>
                                              <ul class="wcml-cs-submenu">
                                                  <li>
                                                      <a>EUR</a>
                                                  </li>
                                              </ul>
                                          </li>
                                      </ul>
                                  </div>
                                  <span class="sku_wrapper">SKU: <span class="sku" [innerHTML]="dto.Id"></span></span>
                                  <span class="tagged_as" style="display: none;" >Tags: <a href="#"
                                                                   rel="tag">Bangles</a>, <a
                                          href="#" rel="tag">Sock</a></span>
                              </div>
                              <div class="mykriva-share-socials">
                                  <h5 class="social-heading">Share: </h5>
                                  <a target="_blank" class="facebook" href="#">
                                      <i class="fa fa-facebook-f"></i>
                                  </a>
                                  <a target="_blank" class="twitter"
                                     href="#"><i class="fa fa-twitter"></i>
                                  </a>
                                  <a target="_blank" class="pinterest"
                                     href="#"> <i class="fa fa-pinterest"></i>
                                  </a>
                                  <a target="_blank" class="googleplus"
                                     href="#"><i class="fa fa-google-plus"></i>
                                  </a>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="mykriva-tabs mykriva-tabs-wrapper" style="display:none">
                      <ul class="tabs dreaming-tabs" role="tablist">
                          <li class="description_tab active" id="tab-title-description" role="tab"
                              aria-controls="tab-description">
                              <a href="#tab-description">Description</a>
                          </li>
                          <li class="additional_information_tab" id="tab-title-additional_information" role="tab"
                              aria-controls="tab-additional_information">
                              <a href="#tab-additional_information">Additional information</a>
                          </li>
                          <li class="reviews_tab" id="tab-title-reviews" role="tab" aria-controls="tab-reviews">
                              <a href="#tab-reviews">Reviews (0)</a>
                          </li>
                      </ul>
                      <div class="mykriva-Tabs-panel mykriva-Tabs-panel--description panel entry-content mykriva-tab"
                           id="tab-description" role="tabpanel" aria-labelledby="tab-title-description">
                          <h2>Description</h2>
                          <div class="container-table">
                              <div class="container-cell">
                                  <h2 class="az_custom_heading">Platea viverra aenean<br>dictumst</h2>
                                  <p>Lorem ipsum dolor sit amet consectetur elit
                                      scelerisque integer, quam dapibus per risus donec semper
                                      vulputate interdum, imperdiet mus rhoncus commodo ultricies
                                      class urna tincidunt. Imperdiet vitae lacus etiam metus ut nisl
                                      curae, conubia enim scelerisque quis facilisis torquent,
                                      ultricies orci faucibus dictumst mauris curabitur. Massa risus
                                      nec sociosqu fames montes accumsan iaculis justo turpis
                                      luctus</p>
                              </div>
                              <div class="container-cell">
                                  <div class="az_single_image-wrapper az_box_border_grey">
                                      <img src="assets/images/single-pro1.jpg"
                                           class="az_single_image-img attachment-full" alt="img"></div>
                              </div>
                          </div>
                          <div class="container-table">
                              <div class="container-cell">
                                  <div class="az_single_image-wrapper az_box_border_grey">
                                      <img src="assets/images/single-pro2.jpg"
                                          class="az_single_image-img attachment-full" alt="img"></div>
                              </div>
                              <div class="container-cell">
                                  <h2 class="az_custom_heading">
                                      Potenti praesent molestie<br>
                                      at viverra</h2>
                                  <p>This generator uses a dictionary of Latin words to construct
                                      passages of Lorem Ipsum text that meet your desired length. The
                                      sentence and paragraph durations and punctuation dispersal are
                                      calculated using Gaussian distribution, based on statistical
                                      analysis of real world texts. This ensures that the generated
                                      Lorem Ipsum text is unique, free of repetition and also
                                      resembles readable text as much as possible.</p>
                              </div>
                          </div>
                      </div>
                      <div class="mykriva-Tabs-panel mykriva-Tabs-panel--additional_information panel entry-content mykriva-tab"
                           id="tab-additional_information" role="tabpanel"
                           aria-labelledby="tab-title-additional_information">
                          <h2>Additional information</h2>
                          <table class="shop_attributes">
                              <tbody>
                              <tr>
                                  <th>Color</th>
                                  <td><p>Blue, Pink, Red, Yellow</p>
                                  </td>
                              </tr>
                              </tbody>
                          </table>
                      </div>
                      <div class="mykriva-Tabs-panel mykriva-Tabs-panel--reviews panel entry-content mykriva-tab"
                           id="tab-reviews" role="tabpanel" aria-labelledby="tab-title-reviews">
                          <div id="reviews" class="mykriva-Reviews">
                              <div id="comments">
                                  <h2 class="mykriva-Reviews-title">Reviews</h2>
                                  <p class="mykriva-noreviews">There are no reviews yet.</p>
                              </div>
                              <div id="review_form_wrapper">
                                  <div id="review_form">
                                      <div id="respond" class="comment-respond">
                                          <span id="reply-title" class="comment-reply-title">Be the first to review “T-shirt with skirt”</span>
                                          <form id="commentform" class="comment-form">
                                              <p class="comment-notes"><span id="email-notes">Your email address will not be published.</span>
                                                  Required fields are marked <span class="required">*</span></p>
                                              <p class="comment-form-author">
                                                  <label for="author">Name&nbsp;<span
                                                          class="required">*</span></label>
                                                  <input id="author" name="author" value="" size="30" required=""
                                                         type="text"></p>
                                              <p class="comment-form-email"><label for="email">Email&nbsp;
                                                  <span class="required">*</span></label>
                                                  <input id="email" name="email" value="" size="30" required=""
                                                         type="email"></p>
                                              <div class="comment-form-rating"><label for="rating">Your rating</label>
                                                  <p class="stars">
                                                      <span>
                                                      <a class="star-1" href="#">1</a>
                                                      <a class="star-2" href="#">2</a>
                                                      <a class="star-3" href="#">3</a>
                                                      <a class="star-4" href="#">4</a>
                                                      <a class="star-5" href="#">5</a>
                                                      </span>
                                                  </p>
                                                  <select title="product_cat" name="rating" id="rating" required=""
                                                          style="display: none;">
                                                      <option value="">Rate…</option>
                                                      <option value="5">Perfect</option>
                                                      <option value="4">Good</option>
                                                      <option value="3">Average</option>
                                                      <option value="2">Not that bad</option>
                                                      <option value="1">Very poor</option>
                                                  </select></div>
                                              <p class="comment-form-comment"><label for="comment">Your
                                                  review&nbsp;<span class="required">*</span></label><textarea
                                                      id="comment" name="comment" cols="45" rows="8"
                                                      required=""></textarea></p><input name="wpml_language_code"
                                                                                        value="en" type="hidden">
                                              <p class="form-submit"><input name="submit" id="submit" class="submit"
                                                                            value="Submit" type="submit"> <input
                                                      name="comment_post_ID" value="27" id="comment_post_ID"
                                                      type="hidden">
                                                  <input name="comment_parent" id="comment_parent" value="0"
                                                         type="hidden">
                                              </p></form>
                                      </div><!-- #respond -->
                                  </div>
                              </div>
                              <div class="clear"></div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div *ngIf="listProductRelated != null && listProductRelated.length > 0" class="col-md-12 col-sm-12 dreaming_related-product">
              <div class="block-title">
                  <h2 class="product-grid-title">
                      Related Products
                      <span></span>
                  </h2>
              </div>
              <div class="owl-slick owl-products equal-container better-height"
                   data-slick="{&quot;arrows&quot;:false,&quot;slidesMargin&quot;:30,&quot;dots&quot;:true,&quot;infinite&quot;:false,&quot;slidesToShow&quot;:4}"
                   data-responsive="[{&quot;breakpoint&quot;:480,&quot;settings&quot;:{&quot;slidesToShow&quot;:2,&quot;slidesMargin&quot;:&quot;10&quot;}},{&quot;breakpoint&quot;:768,&quot;settings&quot;:{&quot;slidesToShow&quot;:2,&quot;slidesMargin&quot;:&quot;10&quot;}},{&quot;breakpoint&quot;:992,&quot;settings&quot;:{&quot;slidesToShow&quot;:3,&quot;slidesMargin&quot;:&quot;20&quot;}},{&quot;breakpoint&quot;:1200,&quot;settings&quot;:{&quot;slidesToShow&quot;:3,&quot;slidesMargin&quot;:&quot;20&quot;}},{&quot;breakpoint&quot;:1500,&quot;settings&quot;:{&quot;slidesToShow&quot;:3,&quot;slidesMargin&quot;:&quot;30&quot;}}]">

                   <div *ngFor="let item of listProductRelated; index as i;" class="product-item style-01 post-27 product type-product status-publish has-post-thumbnail product_cat-table product_cat-new-arrivals product_cat-lamp product_tag-table product_tag-sock  instock shipping-taxable purchasable product-type-variable has-default-attributes"
                        style="display: inline-block; margin-right: 25px; ">
                      <div class="product-inner tooltip-left">
                          <div class="product-thumb">
                              <a class="thumb-link"
                                 href="/{{removeSpace(item.Name)}}/details?id={{item.Id}}" tabindex="i">
                                  <img  class="img-responsive"
                                  src="{{item.DefaultPhoto ? (item.DefaultPhoto) : 'assets/images/placeholder.png'}}"
                                        alt="item.Name" width="300">
                              </a>
                              <div class="flash"><span class="onnew"><span class="text">New</span></span></div>
                              <div class="group-button" style="display: none;">
                                  <div class="yith-wcwl-add-to-wishlist">
                                      <div class="yith-wcwl-add-button show">
                                          <a href="#" class="add_to_wishlist">Add to Wishlist</a>
                                      </div>
                                  </div>
                                  <div class="mykriva product compare-button">
                                      <a href="#" class="compare button">Compare</a>
                                  </div>
                                  <a href="#" class="button yith-wcqv-button">Quick View</a>
                                  <div class="add-to-cart">
                                      <a href="#" class="button product_type_variable add_to_cart_button">Add to
                                          cart</a>
                                  </div>
                              </div>
                          </div>
                          <div class="product-info equal-elem">
                              <h3 class="product-name product_title">
                                  <a href="/{{removeSpace(item.Name)}}/details?id={{item.Id}}"
                                     tabindex="i">{{item.Name}}</a>
                              </h3>
                              <div class="rating-wapper nostar">
                                  <div class="star-rating"><span style="width:0%">Rated <strong
                                          class="rating">0</strong> out of 5</span></div>
                                  <span class="review">(0)</span></div>
                              <span class="price">
                                <span class="mykriva-Price-amount amount">
                                    <span class="mykriva-Price-currencySymbol">$</span>
                                    <span [innerHTML]="item.Price"></span>
                                    <span *ngIf="item.KeyDiamondRequired">
                                        <span style="font-size: 14px;">+</span>
                                    </span>
                                </span>
                              </span>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

      </div>
  </div>
</div>
