import { Injectable, Injector } from '@angular/core';
import { ApiService } from '../../../core/service/api.service' //'/app/core/service/api.service';
import { QueryParameter, AppResponse } from '../../../core/service/QueryParameter' //'app/shared/modal/QueryParameter';
import { environment } from '../../../../environments/environment' //'  environment/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductDetailService {
  private apiurl: string = environment.apiUrl;
  private GetProductDetailUrl: string = this.apiurl + "ProductDetail";
  private SaveProductSessionUrl: string = this.apiurl + "WebsiteProductSession";
  private GetProductRelatedUrl: string = this.apiurl + "ProductRelated";
  

  
  constructor(private injector: Injector, private apiService: ApiService) { }
  public get ApiService(): ApiService {
    if (!this.apiService) {
      this.apiService = this.injector.get(ApiService);
    }
    return this.apiService;
  }

  public GetProductDetail(id: any){
    let url = this.GetProductDetailUrl + "?id="+id;
    return this.ApiService.get<AppResponse<any>>(url);
  }

  public SaveProductSession(dto:any){
    let url = this.SaveProductSessionUrl;
    return this.ApiService.post<AppResponse<any>>(url, dto);
  }

  public GetProductRelatedList(pId:number){
    let url = this.GetProductRelatedUrl + "/"+pId;
    return this.ApiService.get<AppResponse<any>>(url);
  }


}

