<main class="site-main  main-container no-sidebar">
  <div class="container">
    <div class="row">
      <div class="main-content col-md-12">
        <div class="page-main-content">
          <div class="mykriva">
            <div class="mykriva-notices-wrapper"></div>
            <form name="checkout" method="post" class="checkout mykriva-checkout" action="#"
              enctype="multipart/form-data" novalidate="novalidate">

              <h3 style="display: flex;">Select Diamond
                <button type="button" class="button alt ml-auto" name="mykriva_checkout_place_order"
                  (click)="open(content)" id="filterBtn">Filter</button>
              </h3>
              <div class="my-filters">
                <ul class="filter-ul" *ngIf="isFilter">
                  <li class="list-item" *ngIf="maxPrice && minPrice">
                    Price : <span> Min: {{minPrice}} - Max: {{maxPrice}} </span>
                  </li>
                  <li class="list-item" *ngIf="maxSize && minSize">
                    Size : <span> Min: {{minSize}} - Max: {{maxSize}} </span>
                  </li>
                  <li class="list-item" *ngIf="dcVal">
                    Color : <span>{{dcVal}}</span>
                  </li>
                  <li class="list-item" *ngIf="dclVal">
                    Clarity : <span>{{dclVal}}</span>
                  </li>
                  <li class="list-item" *ngIf="flVal">
                    Fluorescence : <span>{{flVal}}</span>
                  </li>
                  <button type="button" class="button btn-danger" *ngIf="isFilter" name="mykriva_checkout_place_order"
                    (click)="clearFilter()" id="clearBtn">Clear</button>
                </ul>

              </div>
              <div *ngIf="isLoading" class="loading-lazy" style="width: 100%; margin: 50px 0px;">
              </div>
              <div class="col2-set" id="customer_details">
                <div class="col-1">
                  <div class="mykriva-billing-fields">
                    <form>
                      <div class="select-diamond-main">
                        <ul class="select-diamond-list">
                          <li class="list-item" *ngFor="let item of list; index as i;">
                            <label class="check-label">
                              <input name='selectedCheck_"{{i}}"' [disabled]="item.disabled"
                                [checked]="item.selectedDiamond" [(ngModel)]="item.selectedDiamond" type="checkbox"
                                (change)="onChangeDiamond(item,$event,this)" style="display: none;">
                              <div class="list-item-inner">
                                <div class="detail-left">
                                  <p>{{item.Shape}}</p>
                                  <p>{{item.Lab}} {{item.CertificateNumber}}</p>
                                  <p>{{item.FluorescenceIntensity}}</p>
                                  <h4 class="price text-green">
                                    ${{item.Price}}
                                  </h4>
                                </div>
                                <div class="detail-right">
                                  <p>{{item.Color}}</p>
                                  <p>{{item.Clarity}}</p>
                                  <p>{{item.Size}} ct</p>
                                </div>
                              </div>
                            </label>
                          </li>
                          <!-- <li *ngIf="isLoading" class="loading-lazy" style="width: 100%; margin: 50px 0px;">
                          </li> -->
                        </ul>
                      </div>
                    </form>
                  </div>

                </div>

              </div>

              <div id="order_review" class="mykriva-checkout-review-order">
                <table class="shop_table mykriva-checkout-review-order-table">

                  <tbody>
                    <tr>
                      <td colspan="2">
                        <img [src]="sessionDTO.ProductImageURL" alt="" class="product-image">
                      </td>
                    </tr>

                    <tr>
                      <th class="product-name" colspan="2">{{sessionDTO.ProductName}} <br> <small> Product ID
                          #{{sessionDTO.ProductId}}</small> </th>
                    </tr>

                    <tr class="cart_item">
                      <td class="product-name" style="width: 75%;">
                        <strong class="product-quantity">{{sessionDTO.MetalFineness}}</strong>
                      </td>
                      <td class="product-total">
                        <span class="mykriva-Price-amount amount"><span
                            class="mykriva-Price-currencySymbol">$</span>{{sessionDTO.MountingPrice}}</span>
                      </td>
                    </tr>
                    <tr *ngIf="sessionDTO.KeyDiamondPrice != null" class="cart_item">
                      <td class="product-name" style="width: 75%;">
                        <strong class="product-quantity">{{sessionDTO.KeyDiamond}}</strong>
                      </td>
                      <td class="product-total">
                        <span class="mykriva-Price-amount amount">
                          <span class="mykriva-Price-currencySymbol">$</span>{{sessionDTO.KeyDiamondPrice}}
                        </span>
                      </td>
                    </tr>
                    <tr *ngIf="sessionDTO.KeyDiamondPrice == null" class="cart_item">
                      <td class="product-name" colspan="2" style="text-align: center;">
                        <strong class="product-quantity">Select the diamond</strong>
                      </td>
                    </tr>

                  </tbody>
                  <tfoot>
                    <tr class="cart-subtotal">
                      <th>Subtotal</th>
                      <td><span class="mykriva-Price-amount amount"><span
                            class="mykriva-Price-currencySymbol">$</span>{{sessionDTO.SubTotal}}</span></td>
                    </tr>
                    <tr class="cart-subtotal">
                      <th>Tax</th>
                      <td><span class="mykriva-Price-amount amount"><span
                            class="mykriva-Price-currencySymbol">$</span>{{sessionDTO.Tax}}</span></td>
                    </tr>
                    <tr class="order-total">
                      <th>Total</th>
                      <td><strong><span class="mykriva-Price-amount amount"><span
                              class="mykriva-Price-currencySymbol">$</span>{{sessionDTO.Total}}</span></strong>
                      </td>
                    </tr>
                  </tfoot>
                </table>

                <div *ngIf="sessionDTO.KeyDiamondPrice != null" class="text-center">
                  <button type="submit" class="button alt" (click)="GoToPaymentPage()"
                    name="mykriva_checkout_place_order" id="place_order" value="Place order" data-value="Place order">
                    Check Out
                  </button>
                </div>


              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Filter Diamond</h4>
      <button type="button" class="close" aria-label="Close" data-dismiss="modal"
        (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">

      <div id="mykriva_price_filter-2" class="widget mykriva widget_price_filter">
        <h4 class="widgettitle">Price<span class="arrow"></span></h4>
        <div class="price_slider_wrapper">
          <!-- <div data-label-reasult="Range:" data-min="minPrice" data-max="maxPrice" data-unit="$" class="price_slider ui-slider ui-slider-horizontal ui-widget ui-widget-content ui-corner-all" data-value-min="100" data-value-max="800"> -->
          <!-- <ngx-slider [(value)]="minPriceVal" [(highValue)]="maxPriceVal" [options]="priceOptions"></ngx-slider> -->
          <div style="display: flex;justify-content: space-between;">
            <div style="display: flex;
                  flex-direction: column;">
              <label>Min.Price</label>
              <input type="text" placeholder="Minimun price" [(ngModel)]="minPrice">
            </div>
            <div style="display: flex;
                  flex-direction: column;">
              <label>Max.Price</label>
              <input type="text" placeholder="Maximun price" [(ngModel)]="maxPrice">
            </div>
          </div>
        </div>
      </div>

      <div id="mykriva_mykriva_layered_nav-4" class="widget mykriva_widget_layered_nav widget_layered_nav">
        <h4 class="widgettitle">Color<span class="arrow"></span></h4>
        <div class="color-group">
          <label class="term-color" *ngFor="let item of dcData; let i = index;" style="min-width: auto;">
            <input type="checkbox" [checked]="item.checked" id="colorChk{{item.DiamondColorId}}" name="term-color"
              (change)="OnchangeCheckColor(item)">
            <i></i>
            <span class="term-name">{{item.Name}} &nbsp;</span>
          </label>
        </div>
      </div>
      <div id="mykriva_mykriva_layered_nav-4" class="widget mykriva_widget_layered_nav widget_layered_nav">
        <h4 class="widgettitle">Clarity<span class="arrow"></span></h4>
        <div class="color-group">
          <label class="term-color" *ngFor="let item of dclData; let i = index;" style="min-width: auto;">
            <input type="checkbox" [checked]="item.checked" id="colorChk{{item.DiamondClarityId}}" name="term-color"
              (change)="OnchangeCheckClarity(item)">
            <i></i>
            <span class="term-name">{{item.Name}} &nbsp;</span>
          </label>
        </div>
      </div>
      <div id="mykriva_mykriva_layered_nav-4" class="widget mykriva_widget_layered_nav widget_layered_nav">
        <h4 class="widgettitle">Fluorescence<span class="arrow"></span></h4>
        <div class="color-group">
          <label class="term-color" *ngFor="let item of flData; let i = index;" style="min-width: auto;">
            <input type="checkbox" [checked]="item.checked" id="colorChk{{item.DiamondFluorescenceId}}"
              name="term-color" (change)="OnchangeCheckFluorescence(item)">
            <i></i>
            <span class="term-name">{{item.Name}} &nbsp;</span>
          </label>
        </div>
      </div>

      <div id="mykriva_price_filter-2" class="widget mykriva widget_price_filter">
        <h4 class="widgettitle">Size<span class="arrow"></span></h4>
        <div class="price_slider_wrapper">
          <ngx-slider [(value)]="minSize" [(highValue)]="maxSize" [options]="sizeOptions"></ngx-slider>
        </div>
      </div>


    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark"
        (click)="modal.dismiss('Cross click');clearFilter()">Clear</button>
      <button type="submit" class="btn btn-outline-primary"
        (click)="modal.close('Save click');applyFilter()">Filter</button>
    </div>
  </ng-template>
</main>
