<header id="header"
[ngClass]="router.url !='/shop' ? 'header style-03 header-dark header-sticky header-transparent' : 'header style-03 header-dark header-sticky'">
  <div class="header-wrap-stick">
      <div class="header-position">
          <div class="header-middle">
              <div class="mykriva-menu-wapper"></div>
              <div class="header-middle-inner">
                  <div class="header-menu">
                      <div class="box-header-nav menu-nocenter">
                          <ul id="menu-primary-menu"
                              class="clone-main-menu mykriva-clone-mobile-menu mykriva-nav main-menu">
                              <li
                                  class="menu-item menu-item-type-post_type ">
                                  <a class="mykriva-menu-item-title" title="Home" [routerLink]="['/']" routerLinkActive="active">Home</a>
                              </li>
                              <li
                                  class="menu-item menu-item-type-post_type ">
                                  <a class="mykriva-menu-item-title" title="Shop" [routerLink]="['/ring/shop']" [queryParams]="{ cid: 1 }" routerLinkActive="active">Ring</a>
                              </li>
                              <li style="display: none;"
                                  class="menu-item menu-item-type-post_type ">
                                  <a class="mykriva-menu-item-title" title="Elements1" [routerLink]="['/earring/shop']" [queryParams]="{ cid: 2 }"  routerLinkActive="active">Earring</a>
                              </li>
                              <li
                                  class="menu-item menu-item-type-post_type ">
                                  <a class="mykriva-menu-item-title" title="Elements2" [routerLink]="['/necklace/shop']" [queryParams]="{ cid: 3 }"  routerLinkActive="active">Necklace</a>
                              </li>
                              <li style="display: none;"
                                  class="menu-item menu-item-type-post_type ">
                                  <a class="mykriva-menu-item-title" title="Elements3" [routerLink]="['/bracelet/shop']" [queryParams]="{ cid: 4 }"  routerLinkActive="active">Bracelet</a>
                              </li>
                          </ul>
                      </div>
                      <div class="block-menu-bar">
                          <a class="menu-bar menu-toggle" href="#">
                              <span></span>
                              <span></span>
                              <span></span>
                          </a>
                      </div>
                  </div>
                  <div class="header-logo">
                      <a [routerLink]="['/']"><img alt="mykriva"
                                                src="assets/images/kriva/krivatext.svg"
                                                class="logo"></a></div>
                  <div class="header-control">
                      <div class="header-control-inner">
                        <a class="mykriva-menu-item-title contact-btn" title="Home" [routerLink]="['/contact']" routerLinkActive="active"> Contact</a>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <div class="header-mobile">
      <div class="header-mobile-left">
          <div class="block-menu-bar">
              <a class="menu-bar menu-toggle" href="#">
                  <span></span>
                  <span></span>
                  <span></span>
              </a>
          </div>
          <div class="header-search mykriva-dropdown">
              <div class="header-search-inner" data-mykriva="mykriva-dropdown">
                  <a href="#" class="link-dropdown block-link">
                      <span class="flaticon-magnifying-glass-1"></span>
                  </a>
              </div>
              <div class="block-search">
                  <form role="search" method="get"
                        class="form-search block-search-form mykriva-live-search-form">
                      <div class="form-content search-box results-search">
                          <div class="inner">
                              <input autocomplete="off" class="searchfield txt-livesearch input" name="s" value=""
                                     placeholder="Search here..." type="text">
                          </div>
                      </div>
                      <input name="post_type" value="product" type="hidden">
                      <input name="taxonomy" value="product_cat" type="hidden">
                      <div class="category">
                          <select title="product_cat" name="product_cat" id="112546160" class="category-search-option"
                                  tabindex="-1"
                                  style="display: none;">
                              <option value="0">All Categories</option>
                              <option class="level-0" value="light">Earrings</option>
                              <option class="level-0" value="chair">Pendants</option>
                              <option class="level-0" value="table">Bangles</option>
                              <option class="level-0" value="bed">Finger Rings</option>
                              <option class="level-0" value="new-arrivals">New arrivals</option>
                              <option class="level-0" value="lamp">Chains</option>
                              <option class="level-0" value="specials">Neckwear</option>
                              <option class="level-0" value="sofas">Bracelets</option>
                          </select>
                      </div>
                      <button type="submit" class="btn-submit">
                          <span class="flaticon-magnifying-glass-1"></span>
                      </button>
                  </form><!-- block search -->
              </div>
          </div>
          <ul class="wpml-menu">
              <li class="menu-item mykriva-dropdown block-language">
                  <a href="#" data-mykriva="mykriva-dropdown">
                      <img src="assets/images/en.png"
                           alt="en" width="18" height="12">
                      English
                  </a>
                  <span class="toggle-submenu"></span>
                  <ul class="sub-menu">
                      <li class="menu-item">
                          <a href="#">
                              <img src="assets/images/it.png"
                                   alt="it" width="18" height="12">
                              Italiano
                          </a>
                      </li>
                  </ul>
              </li>
              <li class="menu-item">
                  <div class="wcml-dropdown product wcml_currency_switcher">
                      <ul>
                          <li class="wcml-cs-active-currency">
                              <a class="wcml-cs-item-toggle">USD</a>
                              <ul class="wcml-cs-submenu">
                                  <li>
                                      <a>EUR</a>
                                  </li>
                              </ul>
                          </li>
                      </ul>
                  </div>
              </li>
          </ul>
      </div>
      <div class="header-mobile-mid">
          <div class="header-logo">
              <a [routerLink]="['/']" routerLinkActive="active"><img alt="mykriva"
                                        src="assets/images/kriva/krivatext.svg"
                                        class="logo"></a></div>
      </div>
      <div class="header-mobile-right">
          <div class="header-control-inner">
            <a class="mykriva-menu-item-title" title="Home" [routerLink]="['/contact']" routerLinkActive="active">Contact</a>
          </div>
      </div>
  </div>
</header>
