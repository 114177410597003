import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { HomeComponent } from './modules/components/home/home.component';
import { MyAccountComponent } from './modules/components/my-account/my-account.component';
import { CartComponent } from './modules/components/cart/cart.component';
import { CheckoutComponent } from './modules/components/checkout/checkout.component';
import { ProductListComponent } from './modules/components/product-list/product-list.component';
import { ProductDetailComponent } from './modules/components/product-detail/product-detail.component';
import { NotFoundComponent } from './core/error/not-found/not-found.component';
import { ProductDiamondComponent } from './modules/components/product-diamond/product-diamond.component';
import { OrderConfirmationComponent } from './modules/components/order-confirmation/order-confirmation.component';
import { ContactComponent } from './modules/components/contact/contact.component';
const routes: Routes = [
  {
    path: 'index',
    component: HomeComponent,
    data: { title: 'Home | Kriva Jewelry', description:'Kriva Jewelry provides best jewelry designs.', ogUrl: '' }
  },
  {
    path: 'profile',
    component: MyAccountComponent,
    data: { title: 'Profile | Kriva Jewelry', description:'Kriva Jewelry provides best jewelry designs.', ogUrl: '' }
  },
  {
    path: 'cart',
    component: CartComponent,
    data: { title: 'Cart | Kriva Jewelry', description:'Kriva Jewelry provides best jewelry designs.', ogUrl: '' }
  },
  {
    path: 'checkout',
    component: CheckoutComponent,
    data: { title: 'Checkout | Kriva Jewelry', description:'Kriva Jewelry provides best jewelry designs.', ogUrl: '' }
  },
  {
    path: ':productname/shop',
    component: ProductListComponent,
    data: { title: 'Product | Kriva Jewelry', description:'Kriva Jewelry provides best jewelry designs.', ogUrl: '' }
  },
  {
    path: ':productname/details',
    component: ProductDetailComponent,
    data: { title: 'Product | Kriva Jewelry', description:'Kriva Jewelry provides best jewelry designs.', ogUrl: '' }
  },
  {
    path: 'diamond',
    component: ProductDiamondComponent,
    data: { title: 'Diamond | Kriva Jewelry', description:'Kriva Jewelry provides best jewelry designs.', ogUrl: '' }
  },
  {
    path: 'orderconfirmation',
    component: OrderConfirmationComponent,
    data: { title: 'Order Confirmation | Kriva Jewelry', description:'Kriva Jewelry provides best jewelry designs.', ogUrl: '' }
  },
  {
    path: 'contact',
    component: ContactComponent,
    data: { title: 'Contact | Kriva Jewelry', description:'Kriva Jewelry provides best jewelry designs.', ogUrl: '' }
  },
  {
    path: "",
    component: HomeComponent,
    data: { title: 'Kriva Jewelry', description:'Kriva Jewelry provides best jewelry designs.', ogUrl: '' }
  },
  {
    path: "**",
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
