import { Injectable, Injector } from '@angular/core';
import { ApiService } from '../../../core/service/api.service' //'/app/core/service/api.service';
import { QueryParameter, AppResponse } from '../../../core/service/QueryParameter' //'app/shared/modal/QueryParameter';
import { environment } from '../../../../environments/environment' //'  environment/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductDiamondService {
  private apiurl: string = environment.apiUrl;
  private GetProductDetailUrl: string = this.apiurl + "ProductDetail";
  private GetProductSessionUrl: string = this.apiurl + "WebsiteProductSession";
  private SaveProductSessionUrl: string = this.apiurl + "WebsiteProductSession";
  private GetProductDiamondUrl: string = this.apiurl + "productdiamond";

  constructor(private injector: Injector, private apiService: ApiService) { }
  public get ApiService(): ApiService {
    if (!this.apiService) {
      this.apiService = this.injector.get(ApiService);
    }
    return this.apiService;
  }

  public GetProductDetail(id: any){
    let url = this.GetProductDetailUrl + "?id="+id;
    return this.ApiService.get<AppResponse<any>>(url);
  }

  public GetProductSession(productId: any, id: any){
    let url = this.GetProductSessionUrl + "?id="+id + "&productId="+ productId;
    return this.ApiService.get<AppResponse<any>>(url);
  }

  public GetProductDiamond(id: any, ds: number, dc: string, dcl: string, minSize: any,  maxSize: any, minPrice: any, maxPrice: any, fl: string, pn: number){
    //long id, int ds, string dc, string dcl, double? minSize, double? maxSize, double? minPrice, double? maxPrice, string fl, int pn
    let url = this.GetProductDiamondUrl + "?id="+id + "&ds="+ds + "&dc="+dc + "&dcl="+dcl + "&minSize="+minSize + "&maxSize="+maxSize + "&minPrice="+minPrice + "&maxPrice="+maxPrice + "&fl="+fl + "&pn="+pn;
    return this.ApiService.get<AppResponse<any>>(url);
  }

  public SaveProductSession(dto:any){
    let url = this.SaveProductSessionUrl;
    return this.ApiService.post<AppResponse<any>>(url, dto);
  }

}

