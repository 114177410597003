import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './core/header/header.component';
import { FooterComponent } from './core/footer/footer.component';
import { HomeComponent } from './modules/components/home/home.component';
import { MyAccountComponent } from './modules/components/my-account/my-account.component';
import { CartComponent } from './modules/components/cart/cart.component';
import { CheckoutComponent } from './modules/components/checkout/checkout.component';
import { ProductListComponent } from './modules/components/product-list/product-list.component';
import { ProductDetailComponent } from './modules/components/product-detail/product-detail.component';
import { NotFoundComponent } from './core/error/not-found/not-found.component';
import { UnauthorizedComponent } from './core/error/unauthorized/unauthorized.component';
import { ProductDiamondComponent } from './modules/components/product-diamond/product-diamond.component';
import { OrderConfirmationComponent } from './modules/components/order-confirmation/order-confirmation.component';
import { ContactComponent } from './modules/components/contact/contact.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';

import { SEOService } from './core/service/seo.service';
import { NgxImageZoomModule } from 'ngx-image-zoom';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    MyAccountComponent,
    CartComponent,
    CheckoutComponent,
    ProductListComponent,
    ProductDetailComponent,
    ProductDiamondComponent,
    OrderConfirmationComponent,
    NotFoundComponent,
    UnauthorizedComponent,
    ContactComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgxSliderModule,
    NgxImageZoomModule
  ],
  providers: [SEOService],
  bootstrap: [AppComponent]
})
export class AppModule { }
