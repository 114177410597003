<div>

<main class="site-main  main-container no-sidebar">
   <div class="container">
       <div class="orderplaced">


           <div  class="mykriva-checkout-review-order">

               <div class="title text-center ">
                  <!--
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns" xmlns:xlink="http://www.w3.org/1999/xlink" height="50px" version="1.1" viewBox="0 0 20 20" width="50px"><title/><desc/><defs/><g fill="none" fill-rule="evenodd" id="Page-1" stroke="none" stroke-width="1"><g fill="#000000" id="Core" transform="translate(-128.000000, -86.000000)"><g id="check-circle-outline" transform="translate(128.000000, 86.000000)"><path d="M5.9,8.1 L4.5,9.5 L9,14 L19,4 L17.6,2.6 L9,11.2 L5.9,8.1 L5.9,8.1 Z M18,10 C18,14.4 14.4,18 10,18 C5.6,18 2,14.4 2,10 C2,5.6 5.6,2 10,2 C10.8,2 11.5,2.1 12.2,2.3 L13.8,0.7 C12.6,0.3 11.3,0 10,0 C4.5,0 0,4.5 0,10 C0,15.5 4.5,20 10,20 C15.5,20 20,15.5 20,10 L18,10 L18,10 Z" id="Shape"/></g></g></g></svg>
                  -->
                  <h3 class="text-green">Your order placed successfully!</h3>
               </div>


               <table class="shop_table mykriva-checkout-review-order-table">
                  <tbody>

                     <tr class="cart_item">
                       <td>
                          Order ID <br>
                          <span class="text-green">
                           <strong>#{{orderDTO.Id}}</strong>
                          </span>
                       </td>
                       <td class="product-total">
                           Product ID <br>
                           #{{orderDTO.ProductId}}
                       </td>
                    </tr>
                     <tr>
                        <th class="product-name" colspan="2">
                            <div class="product-summary">
                               <img [src]="orderDTO.ProductImageURL" alt="" class="product-image">
                               <div class="product-detail">
                                   {{orderDTO.ProductName}}
                               </div>
                            </div>
                           </th>
                     </tr>
                     <tr class="cart_item">
                        <td class="product-name">
                           {{orderDTO.OrderDetailDTO.MetalFineness}}
                        </td>
                        <td class="product-total">
                           <span class="mykriva-Price-amount amount"><span class="mykriva-Price-currencySymbol">$</span>{{orderDTO.OrderDetailDTO.MountingPrice}}</span>
                        </td>
                     </tr>
                     <tr class="cart_item" *ngIf="orderDTO.OrderDetailDTO.KeyDiamondPrice">
                        <td class="product-name">
                           {{orderDTO.OrderDetailDTO.KeyDiamond}}
                        </td>
                        <td class="product-total">
                           <span class="mykriva-Price-amount amount"><span class="mykriva-Price-currencySymbol">$</span>{{orderDTO.OrderDetailDTO.KeyDiamondPrice}}</span>
                        </td>
                     </tr>

                     <tr class="cart-subtotal">
                       <th>Subtotal</th>
                       <td><span class="mykriva-Price-amount amount"><span class="mykriva-Price-currencySymbol">$</span>{{orderDTO.OrderDetailDTO.SubTotal}}</span></td>
                    </tr>
                    <tr class="cart-subtotal">
                     <th>Tax</th>
                     <td><span class="mykriva-Price-amount amount"><span class="mykriva-Price-currencySymbol">$</span>{{orderDTO.OrderDetailDTO.Tax}}</span></td>
                  </tr>
                    <tr class="order-total">
                       <th>Total</th>
                       <td><strong><span class="mykriva-Price-amount amount"><span class="mykriva-Price-currencySymbol">$</span>{{orderDTO.OrderDetailDTO.Total}}</span></strong>
                       </td>
                    </tr>
                    <tr class="order-total" *ngIf="orderDTO.TransactionId">
                      <th>Transaction Id</th>
                      <td><strong><span class="mykriva-Price-amount amount">{{orderDTO.TransactionId}}</span></strong>
                      </td>
                   </tr>
                  </tbody>
                  <tfoot>
                     <tr>
                         <td colspan="2">
                               <div class="address">
                                   <h5>{{orderDTO.CustomerDTO.FirstName}} {{orderDTO.CustomerDTO.LastName}}</h5>
                                   <p>
                                       <strong>Phone:</strong> {{orderDTO.CustomerDTO.Phone}} <br>
                                       <strong>Email:</strong> {{orderDTO.CustomerDTO.Email}} <br>
                                   </p>
                                   <p>
                                       <strong>Address:</strong> {{orderDTO.CustomerDTO.AddressDTO.Address1}} {{orderDTO.CustomerDTO.AddressDTO.Address2}} {{orderDTO.CustomerDTO.AddressDTO.City}} {{orderDTO.CustomerDTO.AddressDTO.State}} {{orderDTO.CustomerDTO.AddressDTO.Zipcode}} {{orderDTO.CustomerDTO.AddressDTO.Country}} <br>
                                       <span *ngIf="orderDTO.AdditionalCustomerNote"> <strong>Order notes:</strong> {{orderDTO.AdditionalCustomerNote}} <br/></span>
                                       <span style="display: none;">Friday, April 26, 2019 | 10:15 PM</span>
                                   </p>
                               </div>
                         </td>
                     </tr>
                  </tfoot>
               </table>
               <div class="text-center mykriva-banner">
                  <a href="/" class="button alt" name="mykriva_checkout_place_order" value="Place order" data-value="Place order">Place
                   another order
                  </a>
               </div>
            </div>
       </div>
   </div>
</main>

</div>
