  <!-- <div class="banner-wrapper has_background">
  <img src="assets/images/banner-for-all2.jpg"
       class="img-responsive attachment-1920x447 size-1920x447" alt="img">
<div class="banner-wrapper-inner">
      <div class="container mykriva-categories style-02">
          <div class="categories-list-owl owl-slick equal-container better-height"
               data-slick="{&quot;arrows&quot;:false,&quot;slidesMargin&quot;:60,&quot;dots&quot;:false,&quot;infinite&quot;:false,&quot;speed&quot;:300,&quot;slidesToShow&quot;:6,&quot;rows&quot;:1}"
               data-responsive="[{&quot;breakpoint&quot;:480,&quot;settings&quot;:{&quot;slidesToShow&quot;:3,&quot;slidesMargin&quot;:&quot;10&quot;}},{&quot;breakpoint&quot;:768,&quot;settings&quot;:{&quot;slidesToShow&quot;:4,&quot;slidesMargin&quot;:&quot;20&quot;}},{&quot;breakpoint&quot;:992,&quot;settings&quot;:{&quot;slidesToShow&quot;:5,&quot;slidesMargin&quot;:&quot;30&quot;}},{&quot;breakpoint&quot;:1200,&quot;settings&quot;:{&quot;slidesToShow&quot;:6,&quot;slidesMargin&quot;:&quot;40&quot;}},{&quot;breakpoint&quot;:1500,&quot;settings&quot;:{&quot;slidesToShow&quot;:6,&quot;slidesMargin&quot;:&quot;50&quot;}}]">
              <div class="categories-item rows-space-0 post-740 page type-page status-publish hentry">
                  <div class="categories-inner">
                      <figure class="categories-thumb">
                          <a href="#" tabindex="-1">
                              <img src="assets/images/cat1.png"
                                   class="img-responsive attachment-370x370 size-370x370" alt="img"> </a>
                      </figure>
                      <h3 class="title">
                          <a href="#" tabindex="-1">
                              Earrings </a>
                      </h3>
                  </div>
              </div>
              <div class="categories-item rows-space-0 post-740 page type-page status-publish hentry">
                  <div class="categories-inner">
                      <figure class="categories-thumb">
                          <a href="#" tabindex="-1">
                              <img src="assets/images/cat2.png"
                                   class="img-responsive attachment-370x370 size-370x370" alt="img"> </a>
                      </figure>
                      <h3 class="title">
                          <a href="#" tabindex="-1"> Pendants </a>
                      </h3>
                  </div>
              </div>
              <div class="categories-item rows-space-0 post-740 page type-page status-publish hentry">
                  <div class="categories-inner">
                      <figure class="categories-thumb">
                          <a href="#" tabindex="0">
                              <img src="assets/images/cat3.png"
                                   class="img-responsive attachment-370x370 size-370x370" alt="img"> </a>
                      </figure>
                      <h3 class="title">
                          <a href="#" tabindex="0">
                              Bangles </a>
                      </h3>
                  </div>
              </div>
              <div class="categories-item rows-space-0 post-740 page type-page status-publish hentry">
                  <div class="categories-inner">
                      <figure class="categories-thumb">
                          <a href="#" tabindex="0">
                              <img src="assets/images/cat4.png"
                                   class="img-responsive attachment-370x370 size-370x370" alt="img"> </a>
                      </figure>
                      <h3 class="title">
                          <a href="#" tabindex="0">
                              Finger Rings </a>
                      </h3>
                  </div>
              </div>
              <div class="categories-item rows-space-0 post-740 page type-page status-publish hentry">
                  <div class="categories-inner">
                      <figure class="categories-thumb">
                          <a href="#" tabindex="0">
                              <img src="assets/images/cat5.png"
                                   class="img-responsive attachment-370x370 size-370x370" alt="img"> </a>
                      </figure>
                      <h3 class="title">
                          <a href="#" tabindex="0">
                              Chains </a>
                      </h3>
                  </div>
              </div>
              <div class="categories-item rows-space-0 post-740 page type-page status-publish hentry">
                  <div class="categories-inner">
                      <figure class="categories-thumb">
                          <a href="#" tabindex="0">
                              <img src="assets/images/cat6.png"
                                   class="img-responsive attachment-370x370 size-370x370" alt="img"> </a>
                      </figure>
                      <h3 class="title">
                          <a href="#" tabindex="0">
                              Bracelets </a>
                      </h3>
                  </div>
              </div>
              <div class="categories-item rows-space-0 post-740 page type-page status-publish hentry">
                  <div class="categories-inner">
                      <figure class="categories-thumb">
                          <a href="#" tabindex="0">
                              <img src="assets/images/cat7.png"
                                   class="img-responsive attachment-370x370 size-370x370" alt="img"> </a>
                      </figure>
                      <h3 class="title">
                          <a href="#" tabindex="0">
                              Pendant Set </a>
                      </h3>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>-->
<div class="my-container">
  <div class="main-container shop-page left-sidebar">
    <h1 class="page-title">Shop</h1>
    <div class="container">
        <div class="row">
            <div class="main-content col-xl-9 col-lg-8 col-md-8 col-sm-12 has-sidebar">
                <div class="shop-control shop-before-control" style="display: none;">
                    <div class="grid-view-mode">
                        <form>
                            <a href="#" data-toggle="tooltip" data-placement="top"
                               class="modes-mode mode-grid display-mode active" value="grid">
                                <span class="button-inner">
                                    Shop Grid
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </span>
                            </a>
                            <a href="#" data-toggle="tooltip" data-placement="top"
                               class="modes-mode mode-list display-mode " value="list">
                                <span class="button-inner">
                                    Shop List
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </span>
                            </a>
                        </form>
                    </div>
                    <form class="mykriva-ordering" method="get">
                        <select title="product_cat" name="orderby" class="orderby">
                            <option value="menu_order" selected="selected">Default sorting</option>
                            <option value="popularity">Sort by popularity</option>
                            <option value="rating">Sort by average rating</option>
                            <option value="date">Sort by latest</option>
                            <option value="price">Sort by price: low to high</option>
                            <option value="price-desc">Sort by price: high to low</option>
                        </select>
                    </form>
                    <form class="per-page-form">
                        <label>
                            <select class="option-perpage">
                                <option value="12" selected="">
                                    Show 12
                                </option>
                                <option value="5">
                                    Show 05
                                </option>
                                <option value="10">
                                    Show 10
                                </option>
                                <option value="12">
                                    Show 12
                                </option>
                                <option value="15">
                                    Show 15
                                </option>
                                <option value="20">
                                    Show All
                                </option>
                            </select>
                        </label>
                    </form>
                </div>
                <div class=" auto-clear mykriva-products">
                    <ul class="row products columns-3">
                        <li *ngFor="let item of List; index as i;"
                        class="product-item wow fadeInUp product-item rows-space-30 col-bg-5 col-xl-5 col-lg-6 col-md-6 col-sm-6 col-ts-6 style-01 post-24 product type-product status-publish has-post-thumbnail product_cat-chair product_cat-table product_cat-new-arrivals product_tag-light product_tag-hat product_tag-sock first instock featured shipping-taxable purchasable product-type-variable has-default-attributes"
                            data-wow-duration="1s" data-wow-delay="0ms" data-wow="fadeInUp">
                            <div class="product-inner tooltip-left">
                              <div class="product-thumb">
                                  <a class="thumb-link" href="/{{removeSpace(item.Name)}}/details?id={{item.Id}}">
                                      <img class="img-responsive"
                                           src="{{item.DefaultPhoto ? (item.DefaultPhoto) : 'assets/images/placeholder.png'}}"
                                           alt="item.Name" width="600">
                                  </a>
                                  <div class="flash">
                                      <span class="onnew"><span class="text">New</span></span>
                                  </div>
                                  <a href="/{{removeSpace(item.Name)}}/details?id={{item.Id}}" class="button yith-wcqv-button" data-product_id="24">Quick View</a>
                                  <div class="group-button">
                                  </div>
                              </div>
                              <div class="product-info equal-elem">
                                  <h3 class="product-name product_title">
                                      <a href="{{removeSpace(item.Name)}}/details?id={{item.Id}}" [innerHTML]="item.Name"></a>
                                  </h3>
                                  <div class="rating-wapper nostar">
                                      <div class="star-rating"><span style="width:0%">Rated <strong
                                              class="rating">0</strong> out of 5</span></div>
                                      <span class="review">(0)</span></div>
                                  <span class="price">
                                      <span class="mykriva-Price-amount amount">
                                          <span class="mykriva-Price-currencySymbol">$</span>
                                          <span [innerHTML]="item.Price"></span>
                                          <span *ngIf="item.KeyDiamondRequired">
                                              <span style="font-size: 10px;"> (without center stone)</span>
                                          </span>
                                      </span>
                                  </span>
                                  <div class="mykriva-product-details__short-description">
                                      <p>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac
                                          turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget,
                                          tempor sit amet, ante.</p>
                                      <ul style="display: none;">
                                          <li>Water-resistant fabric with soft lycra detailing inside</li>
                                          <li>CLean zip-front, and three piece hood</li>
                                          <li>Subtle branding and diagonal panel detail</li>
                                      </ul>
                                  </div>
                              </div>
                              <div class="group-button">
                                  <div class="group-button-inner">
                                      <div class="yith-wcwl-add-to-wishlist">
                                          <div class="yith-wcwl-add-button show">
                                              <a href="#" class="add_to_wishlist">Add to Wishlist</a>
                                          </div>
                                      </div>
                                      <div class="mykriva product compare-button">
                                          <a href="#" class="compare button">Compare</a>
                                      </div>
                                      <a href="#" class="button yith-wcqv-button">Quick View</a>
                                      <div class="add-to-cart">
                                          <a href="#" class="button product_type_variable add_to_cart_button">Select
                                              options</a>
                                      </div>
                                  </div>
                              </div>
                          </div>
                        </li>


  <!--
                        <li class="product-item wow fadeInUp product-item rows-space-30 col-bg-4 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-ts-6 style-01 post-26 product type-product status-publish has-post-thumbnail product_cat-light product_cat-chair product_cat-sofas product_tag-light product_tag-hat  instock featured shipping-taxable product-type-external"
                            data-wow-duration="1s" data-wow-delay="0ms" data-wow="fadeInUp">
                            <div class="product-inner tooltip-left">
                                <div class="product-thumb">
                                    <a class="thumb-link" href="#">
                                        <img class="img-responsive"
                                             src="assets/images/apro141-1-600x778.jpg"
                                             alt="Riona Pearl" width="600" height="778">
                                    </a>
                                    <div class="flash">
                                        <span class="onnew"><span class="text">New</span></span></div>
                                    <a href="#" class="button yith-wcqv-button" data-product_id="24">Quick View</a>
                                    <div class="group-button">
                                        <div class="yith-wcwl-add-to-wishlist">
                                            <div class="yith-wcwl-add-button show">
                                                <a href="#" class="add_to_wishlist">Add to Wishlist</a>
                                            </div>
                                        </div>
                                        <div class="mykriva product compare-button">
                                            <a href="#" class="compare button">Compare</a>
                                        </div>
                                        <a href="#" class="button yith-wcqv-button">Quick View</a>
                                        <div class="add-to-cart">
                                            <a href="#" class="button product_type_external add_to_cart_button">Buy it
                                                on Amazon</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="product-info equal-elem">
                                    <h3 class="product-name product_title">
                                        <a href="#">Riona Pearl</a>
                                    </h3>
                                    <div class="rating-wapper ">
                                        <div class="star-rating"><span style="width:100%">Rated <strong class="rating">5.00</strong> out of 5</span>
                                        </div>
                                        <span class="review">(1)</span></div>
                                    <span class="price"><span class="mykriva-Price-amount amount"><span
                                            class="mykriva-Price-currencySymbol">$</span>207.00</span></span>
                                    <div class="mykriva-product-details__short-description">
                                        <p>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac
                                            turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget,
                                            tempor sit amet, ante.</p>
                                        <ul>
                                            <li>Water-resistant fabric with soft lycra detailing inside</li>
                                            <li>CLean zip-front, and three piece hood</li>
                                            <li>Subtle branding and diagonal panel detail</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="group-button">
                                    <div class="group-button-inner">
                                        <div class="yith-wcwl-add-to-wishlist">
                                            <div class="yith-wcwl-add-button show">
                                                <a href="#" class="add_to_wishlist">Add to Wishlist</a>
                                            </div>
                                        </div>
                                        <div class="mykriva product compare-button">
                                            <a href="#" class="compare button">Compare</a>
                                        </div>
                                        <a href="#" class="button yith-wcqv-button">Quick View</a>
                                        <div class="add-to-cart">
                                            <a href="#" class="button product_type_variable add_to_cart_button">Select
                                                options</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                      -->


                    </ul>
                </div>
                <div class="shop-control shop-after-control" style="display: none;">
                    <nav class="mykriva-pagination">
                        <span class="page-numbers current">1</span>
                        <a class="page-numbers" href="#">2</a>
                        <a class="next page-numbers" href="#">Next</a>
                    </nav>
                    <p class="mykriva-result-count">Showing 1–12 of 20 results</p>
                </div>
            </div>
            <div class="sidebar col-xl-3 col-lg-4 col-md-4 col-sm-12">
                <div id="widget-area" class="widget-area shop-sidebar">

                    <div id="mykriva_price_filter-2" class="widget mykriva widget_price_filter" style="display: none;" >
                        <h2
                            class="widgettitle">Filter By Price<span class="arrow"></span></h2>
                        <form method="get" action="#">
                            <div class="price_slider_wrapper">
                                <div data-label-reasult="Range:" data-min="0" data-max="1000" data-unit="$"
                                     class="price_slider" data-value-min="100" data-value-max="800">
                                </div>
                                <div class="price_slider_amount">
                                    <button type="submit" class="button">Filter</button>
                                    <div class="price_label">
                                        Price: <span class="from">$100</span> — <span class="to">$800</span>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div *ngIf="SubCategoryList.length > 0"
                         id="mykriva_mykriva_layered_nav-4"
                         class="widget mykriva widget_layered_nav mykriva-widget-layered-nav">
                        <h2 class="widgettitle">Filter By
                          <span *ngIf="cId == 1">Ring</span>
                          <span *ngIf="cId == 2">Earring</span>
                          <span *ngIf="cId == 3">Necklace</span>
                          <span *ngIf="cId == 4">Bracelet</span>
                          <span class="arrow"></span></h2>
                          <ul class="mykriva-widget-layered-nav-list">
                              <li *ngFor="let item of SubCategoryList; index as i;"
                                class="mykriva-widget-layered-nav-list__item mykriva-layered-nav-term">
                                  <label>
                                      <input type="checkbox" name="subCategory" (change)="onChangeSubCategory(item,$event)">
                                      <span [innerHTML]="item.Name"></span>
                                      <span class="count"></span>
                                  </label>
                              </li>
                          </ul>
                    </div>
                    <div id="mykriva_layered_nav-6"
                         class="widget mykriva widget_layered_nav mykriva-widget-layered-nav">
                        <h2 class="widgettitle">Filter By Diamond Shape<span class="arrow"></span></h2>
                        <ul class="mykriva-widget-layered-nav-list">
                            <li *ngFor="let item of DiamondShapeList; index as i;"
                              class="mykriva-widget-layered-nav-list__item mykriva-layered-nav-term">
                              <label>
                                  <input type="checkbox" name="diamondShape" (change)="onChangeDiamondShape(item,$event)">
                                  <span [innerHTML]="item.Name"></span>
                                  <span class="count"></span>
                              </label>
                            </li>
                        </ul>
                    </div>
                    <div id="mykriva_product_categories-3"
                         class="widget mykriva widget_layered_nav mykriva-widget-layered-nav">
                          <h2 class="widgettitle">Filter By Gender<span class="arrow"></span></h2>
                          <ul class="mykriva-widget-layered-nav-list">
                              <li *ngFor="let item of GenderList; index as i;"
                                class="mykriva-widget-layered-nav-list__item mykriva-layered-nav-term">
                                  <label>
                                    <input type="checkbox" name="gender" (change)="onChangeGender(item,$event)">
                                    <span [innerHTML]="item.Name"></span>
                                    <span class="count"></span>
                                  </label>
                              </li>
                          </ul>
                    </div>
                </div><!-- .widget-area -->
            </div>
        </div>
    </div>
  </div>
</div>
